import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PopUpModalComponent } from 'src/app/components/pop-up-modal/pop-up-modal.component';
import { DialogsService } from 'src/app/services/dialog/dialog.service';
import { UserDataService } from 'src/app/services/user-data/user-data.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetForm: FormGroup | any;
  showErrormsg = false;
  newPasswordIcon: boolean | any;
  confirmPasswordIcon: boolean | any;

  constructor(private userDataService: UserDataService,
    private dialog: MatDialog,
    private dialogsService: DialogsService) { }

  ngOnInit(): void {
    this.createFormControls();
  }

  get newpassword() {
    return this.resetForm.get('newPassword');
  }

  get confirmPassword() {
    return this.resetForm.get('confirmPassword');
  }

  createFormControls() {
    this.resetForm = new FormGroup({
      email: new FormControl('', Validators.required),
      newPassword: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required)
    });
  }

  removeValidators(form: FormGroup | any) {
    Object.keys(form.controls).map((key) => {
      form.get(key).clearValidators();
      form.get(key).updateValueAndValidity();
    });
  }

  reset(resetForm: any) {
    if (this.newpassword.value && this.confirmPassword.value && this.newpassword.value !== this.confirmPassword.value) {
      this.showErrormsg = true;
    } else {
      this.dialogsService.confirm();
      this.showErrormsg = false;
      this.userDataService.changeUserPassword(resetForm.value).subscribe((success) => {
        this.resetForm.reset();
        this.removeValidators(resetForm);
        this.resetForm.setErrors({ 'invalid': true });
        this.dialogsService.closeModal();
        this.dialog.open(PopUpModalComponent, { data: { message: success.msg } });
      }, (error) => {
        this.dialogsService.closeModal();
        this.dialog.open(PopUpModalComponent, { data: { message: error.error.msg } });
      });
    }
  }

}
