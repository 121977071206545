import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SupportAttachViewComponent } from '../support-attach-view/support-attach-view.component';

@Component({
  selector: 'app-image-view',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.scss']
})
export class ImageViewComponent implements OnInit {
  imageData: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: SupportAttachViewComponent

  ) {
    this.imageData = data;
    console.log("THE DATA IMG_VIEW", this.imageData.data);
  }

  ngOnInit(): void {
  }

}
