import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { ChartComponent } from 'ng-apexcharts';
// import {
//   ApexNonAxisChartSeries,
//   ApexResponsive,
//   ApexChart,
// } from 'ng-apexcharts';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexStroke,
  ApexGrid
} from "ng-apexcharts";

// export type ChartOptions = {
//   series: ApexNonAxisChartSeries;
//   chart: ApexChart;
//   responsive: ApexResponsive[];
//   labels: any;
// };

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
};

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent {
  @ViewChild('chart') chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;
  pieChartData: any;
  type: any = 'donut';

  //LINE CHART CHANGES
  // constructor() {
  //   this.chartOptions = {
  //     series: [
  //       {
  //         name: "Desktops",
  //         data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
  //       }
  //     ],
  //     chart: {
  //       height: 350,
  //       type: "line",
  //       zoom: {
  //         enabled: false
  //       }
  //     },
  //     dataLabels: {
  //       enabled: false
  //     },
  //     stroke: {
  //       curve: "straight"
  //     },
  //     title: {
  //       text: "Product Trends by Month",
  //       align: "left"
  //     },
  //     grid: {
  //       row: {
  //         colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
  //         opacity: 0.5
  //       }
  //     },
  //     xaxis: {
  //       categories: [
  //         "Jan",
  //         "Feb",
  //         "Mar",
  //         "Apr",
  //         "May",
  //         "Jun",
  //         "Jul",
  //         "Aug",
  //         "Sep"
  //       ]
  //     }
  //   };
  // }

  constructor(@Inject(MAT_DIALOG_DATA) public data: PieChartComponent) {
    this.pieChartData = data;    
    if(this.pieChartData.contentType=='books'){
      this.displayBooksChart();
    }
    else if(this.pieChartData.contentType=='performance'){
      this.displayPerformanceChart();
    }  
  }

  //ngOnInit(): void {}

  changeChartDesign(value: any) {
    this.type = value;
    if(this.pieChartData.contentType=='books'){
      this.displayBooksChart();
    }
    else if(this.pieChartData.contentType=='performance'){
      this.displayPerformanceChart();
    }
  }

  displayBooksChart() {
    this.chartOptions = {
      series: [
        this.pieChartData.bookProgress.draft * 10,
        this.pieChartData.bookProgress.teamCheck * 10,
        this.pieChartData.bookProgress.backTranslation * 10,
        this.pieChartData.bookProgress.communityCheck * 10,
        this.pieChartData.bookProgress.consultantCheck * 10,
        this.pieChartData.bookProgress.citCheck * 10,
        this.pieChartData.bookProgress.masterRecord * 10,
        this.pieChartData.bookProgress.publishing * 10,
      ],
      chart: {
        type: this.type,
      },
      // labels: [
      //   'MTT Progress',
      //   'Facilitator Progress',
      //   'CommunityCheck Progress',
      //   'Consultant Progress',
      //   'MasterRecording Progress',
      // ],
      labels: [
        'Draft',
        'Team Check',
        'Back Transaltion',
        'Community Check',
        'Consultant Check',
        'CIT Check',
        'Master Recording',
        'Publishing'
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
  }

  displayPerformanceChart() {
    this.chartOptions = {
      series: [
        this.pieChartData.performanceProgress[0] * 10,
        this.pieChartData.performanceProgress[1] * 10,
        this.pieChartData.performanceProgress[2] * 10,
        this.pieChartData.performanceProgress[3] * 10,
        this.pieChartData.performanceProgress[4] * 10,
        this.pieChartData.performanceProgress[5] * 10,
        this.pieChartData.performanceProgress[6] * 10, 
      ],
      chart: {
        type: this.type,
      },
      // labels: [
      //   'MTT Progress',
      //   'Facilitator Progress',
      //   'CommunityCheck Progress',
      //   'Consultant Progress',
      //   'MasterRecording Progress',
      // ],
      labels: [
        'Drafting',
        'Team Review',
        'Community Review',
        'Back Translation',
        'Consultant Review',
        'Master Record',
        'Publishing'
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
  }
}

