<mat-card class="mat-card reset-form">
    <mat-card-header  class="background-primary border-top-5">
        <mat-card-title class="font-primary" >Create Language</mat-card-title>
    </mat-card-header>
    <form [formGroup]="createLanguageForm" class="font-primary">
        <mat-card-content>
            <mat-form-field>
                <mat-label>Select Region</mat-label>
                <mat-select formControlName="region" required ngDefaultControl (selectionChange)="getCountries($event)">
                    <mat-option *ngFor="let region of regions; trackBy: trackByIndex"
                                [value]="region"
                                class="mat-option-custom" >
                        {{ region.regionName }}
                     </mat-option>
                </mat-select>
                <mat-error *ngIf="createLanguageForm.controls.region.errors?.required">Please select region
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Select Country</mat-label>
                <mat-select formControlName="country" required ngDefaultControl>
                    <mat-option *ngFor="let country of countries; trackBy: trackByIndex"
                                [value]="country"
                                class="mat-option-custom">
                        {{ country.countryName }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="createLanguageForm.controls.country.errors?.required">Please select country
                </mat-error>
            </mat-form-field>
            <mat-form-field color="accent">
                <input
                        matInput
                        placeholder="Language"
                        formControlName="language"
                        required
                >
                <mat-error *ngIf="createLanguageForm.controls.language.errors?.required">Please enter language
                </mat-error>
            </mat-form-field>
            <div class="button-row">
                <button mat-raised-button [ngClass]="{'btn-primary rounded-sm': createLanguageForm.valid}" class="mr-3"
                        [disabled]="!createLanguageForm.valid"
                        (click)="createLanguage();createLanguageForm['submitted']=false;">Create Language
                </button>
            </div>
        </mat-card-content>
    </form>
</mat-card>

