import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDataService } from 'src/app/services/user-data/user-data.service';

@Component({
  selector: 'app-edit-user-detail',
  templateUrl: './edit-user-detail.component.html',
  styleUrls: ['./edit-user-detail.component.scss']
})
export class EditUserDetailComponent implements OnInit {

  editUserDetails: FormGroup | any;
  userId: string | any;
  userCurrentTab: string | any;
  requiredFileds = ['firstName', 'lastName', 'email', 'mobileNumber'];

  constructor(private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private userDataService: UserDataService) { }

  ngOnInit(): void {
    this.createFormControls();
    this
      .activatedRoute
      .params
      .subscribe((params: any) => {
        this.userId = params.id;
        this.userCurrentTab = params.role;
        this.userDataService.getUserById(this.userId).subscribe((userData: any) => {
          this.requiredFileds.forEach((data) => {
            this.editUserDetails.controls[data].setValue(userData[data]);
          });
        });
      });
  }

  createFormControls() {
    this.editUserDetails = new FormGroup({
      firstName: new FormControl('', [Validators.required,
      Validators.pattern(/^[a-z,A-Z,0-9][a-z,A-Z,0-9\s]*$/)]),
      lastName: new FormControl('', [Validators.required,
      Validators.pattern(/^[a-z,A-Z,0-9][a-z,A-Z,0-9\s]*$/)]),
      email: new FormControl({ value: '', disabled: true }, Validators.required),
      mobileNumber: new FormControl('', [Validators.required,
      Validators.pattern(`[0-9]{10,11}`)])
    });
  }

  updateUserDetails() {
    this.userDataService.updateUserData(this.editUserDetails.value, this.userId).subscribe((data: any) => {
      if (data) {
        this.router.navigateByUrl('/user-details');
      }
    });
  }

}
