import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BibleBooksService } from 'src/app/services/bible-book/bible-book.service';
import { DialogsService } from 'src/app/services/dialog/dialog.service';

@Component({
  selector: 'app-project-dashboard',
  templateUrl: './project-dashboard.component.html',
  styleUrls: ['./project-dashboard.component.scss']
})
export class ProjectDashboardComponent implements OnInit {
  isDisplay: boolean = true;
  tableData: any;
  itemSerialNumber: any;
  search: any;
  displayedColumns: any = [
    'position',
    'projectName',
    'language',
    'draft',
    'upload',
    // 'masterRecord',
    // 'consultantCheck',
    // 'communityCheck',
    // 'backTranslation',
    // 'publishing',
    // 'teamCheck'
  ]
  sourceLanguage: any;
  fileType:string='';
  bookName: any;
  chapterName: any; 

  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  dataSource = new MatTableDataSource<any>([]);

  constructor(
    private dialog: MatDialog,
    public bibleBooksService: BibleBooksService,
    private dialogService: DialogsService,
    public dialogRef: MatDialogRef<ProjectDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.tableData = data.data;
    this.sourceLanguage = data.sourceLanguage;
    this.fileType=data.fileType;
    this.bookName = data.bookName;
    this.chapterName = data.chapterName;

  }

  ngOnInit(): void {
    this.itemSerialNumber = 1;
    this.dataSource = new MatTableDataSource(this.tableData);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  changePage(evn: any) {
    this.itemSerialNumber = evn.pageSize * evn.pageIndex + 1;
  }

  filterProjects(search: any) {
    const allRequest = this.tableData.filter((request: any) =>
      request.projectName.includes(search) || request.language.includes(search));
    this.dataSource = new MatTableDataSource(allRequest);
  }

  async closeModal() {
    this.dialogRef.close();
  }

  async deleteSource() {
    let filter={
      sourceLanguage: this.sourceLanguage,
    }
    await this.dialogService.confirm();
    await this.bibleBooksService.deleteSourceProjectList(filter).subscribe(async (response) => {
      await this.dialogService.closeModal();
      this.dialogRef.close({ status: "ok", message: "Source has been deleted" });
    });
  }

  async deleteSourceBookWise() {
    let filter = {
      sourceLanguage: this.sourceLanguage,
      fileType:this.fileType,
      bookName: this.bookName
    }
    await this.dialogService.confirm();
    await this.bibleBooksService.deleteSourceProjectBookWise(filter).subscribe(async (response) => {
      await this.dialogService.closeModal();
      this.dialogRef.close({ status: "ok", message: "Book has been deleted" });
    });

  }

  async deleteSourceChapterWise() {
    let filter = {
      sourceLanguage: this.sourceLanguage,
      fileType:this.fileType,
      bookName: this.bookName,
      chapterName: this.chapterName
    }
    await this.dialogService.confirm();
    await this.bibleBooksService.deleteSourceProjectChapterWise(filter).subscribe(async (response) => {
      await this.dialogService.closeModal();
      this.dialogRef.close({ status: "ok", message: "Chapter has been deleted" });
    });
  }

  async delete() {
    if (this.bookName != undefined && this.chapterName) {
      await this.deleteSourceChapterWise();
    } else if (this.bookName != undefined) {
      await this.deleteSourceBookWise();
    } else {
      await this.deleteSource();
    }
  }
}