import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PopUpModalComponent } from 'src/app/components/pop-up-modal/pop-up-modal.component';
import { DialogsService } from 'src/app/services/dialog/dialog.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { UserDataService } from 'src/app/services/user-data/user-data.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit {
  isAdmin: string | any;
  userDetails: FormGroup | any;
  roles: any[] = [
    { value: '', roleName: 'CIT' },
    { value: '', roleName: 'Consultant' },
    { value: '', roleName: 'Coordinator' },
    { value: '', roleName: 'Country Coordinator' },
    { value: '', roleName: 'Community' },
    { value: '', roleName: 'Facilitator' },
    { value: '', roleName: 'MTT' },
    { value: '', roleName: 'Technician' },
    { value: '', roleName: 'Translation Manager' },
  ];
  regions: any[] | any;
  countriesList: any[] | any;
  languages: any[] | any;

  constructor(
    private formBuilder: FormBuilder,
    private languageService: LanguageService,
    private userDataService: UserDataService,
    private router: Router,
    private dialogService: DialogsService,
    private dialog: MatDialog
  ) {
    this.isAdmin = sessionStorage.getItem('isAdmin');
  }

  ngOnInit(): void {
    this.createFormControls();
    this.getRegions();
  }
  getRegions() {
    this.languageService.getRegions().subscribe((response) => {
      this.regions = this.alphaOrder(response, 'regionName');
    });
  }
  getCountries(event: any) {
    this.userDetails.controls['country'].setValue(null);
    const regionId = event.value.id;
    this.languageService
      .getCountriesByRegion(regionId)
      .subscribe((response) => {
        this.countriesList = this.alphaOrder(response, 'countryName');
        // console.log(this.countriesList);
      });
  }

  getLanguages(event: any) {
    this.userDetails.controls['language'].setValue(null);
    const countryId = event.value.id;
    this.languageService
      .getLanguagesByCountry(countryId)
      .subscribe((response) => {
        this.languages = this.alphaOrder(response, 'languageName');
        // console.log(this.languages);
      });
  }

  alphaOrder(list: any, fieldName: any) {
    return list.sort((a: any, b: any) => {
      if (a[fieldName] > b[fieldName]) return 1;
      else if (a[fieldName] < b[fieldName]) return -1;
      else return 0;
    });
  }

  createFormControls() {
    this.userDetails = new FormGroup({
      role: new FormControl('', Validators.required),
      firstname: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-z,A-Z,0-9][a-z,A-Z,0-9\s]*$/),
      ]),
      lastname: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-z,A-Z,0-9][a-z,A-Z,0-9\s]*$/),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,20}$/),
      ]),
      password: new FormControl('', Validators.required),
      region: new FormControl(''),
      country: new FormControl(''),
      language: new FormControl(''),
      mobileNumber: new FormControl('', [
        Validators.required,
        Validators.pattern(`[0-9]{10,11}`),
      ]),
    });

    this.userDetails.get('role').valueChanges.subscribe((role: any) => {
      if (
        role !== 'Consultant' &&
        role !== 'CIT' &&
        role !== 'Technician' &&
        role !== 'Translation Manager' &&
        role !== 'Coordinator'
      ) {
        const validators = [Validators.required];
        this.userDetails.addControl('region', new FormControl('', validators));
        this.userDetails.addControl('country', new FormControl('', validators));
        this.userDetails.addControl(
          'language',
          new FormControl('', validators)
        );
      } else {
        this.userDetails.removeControl('region');
        this.userDetails.removeControl('country');
        this.userDetails.removeControl('language');
      }

      // if (role === 'Technician') {
      //   const passValidators = [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')];
      //   this.userDetails.get('password').setValue(null);
      //   //this.userDetails.controls["password"].markAsTouched();
      //   this.userDetails.controls['password'].setValidators(passValidators);
      // } else if (role !== 'Technician') {
      //   //this.userDetails.get('password').setValue(null);
      //   this.userDetails.controls['password'].clearValidators()
      //   this.userDetails.controls['password'].setValidators(Validators.required);
      // }
      this.userDetails.updateValueAndValidity();
    });
  }

  validatePassword(passwordParameter: any) {
    const strongPassword = new RegExp(
      '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'
    );
    //const mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))')
    const validatePass = strongPassword.test(passwordParameter) ? true : false;
    return validatePass;
  }

  createUser() {
    // if (this.userDetails.value.role === 'Technician' && !this.validatePassword(this.userDetails.value.password)) {
    //   return false;
    // }
    const userData = {
      role: this.userDetails.value.role,
      firstname: this.userDetails.value.firstname,
      lastname: this.userDetails.value.lastname,
      email: this.userDetails.value.email.toLowerCase(),
      password: this.userDetails.value.password,
      region: this.userDetails.value.region
        ? this.userDetails.value.region.regionName
        : '',
      country: this.userDetails.value.country
        ? this.userDetails.value.country.countryName
        : '',
      language: this.userDetails.value.language
        ? this.userDetails.value.language.languageName
        : '',
      mobileNumber: this.userDetails.value.mobileNumber,
    };
    this.dialogService.confirm();
    this.userDataService.createUser(userData).subscribe(
      (signupData: any) => {
        if (signupData instanceof HttpResponse) {
          this.dialogService.closeModal();
          if (signupData.status === 200) {
            this.dialog.open(PopUpModalComponent, {
              data: {
                message: 'User Created Successfully',
              },
            });
          }
        }
        this.userDetails.reset();
      },
      (error: any) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 409) {
            this.dialogService.closeModal();
            const dialogRef = this.dialog.open(PopUpModalComponent, {
              data: {
                message: 'User Already Exist ',
              },
            });
          } else {
            this.dialogService.closeModal();
            this.dialog.open(PopUpModalComponent, {
              data: {
                message: 'User Not Created',
              },
            });
          }
        }
      }
    );
  }

  homePage() {
    this.router.navigateByUrl('');
  }
}
