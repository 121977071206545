  <div class="close-button">
    <button mat-mini-fab (click)="closeModal()"
    aria-label="Example icon button with a filter list icon">
    <mat-icon class="bug-mat-icon">close</mat-icon>
  </button>
  </div>
  <div>
    <div class="bug-issue">
      <div class="bug-font">Issue: {{ bugData.typeOfBug }}</div>
      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select (selectionChange)="SelectSupportProgress($event.value)" [(value)]="statusValue">
          <mat-option *ngFor="let data of supportStatus" [value]="data">{{data}}</mat-option>
        </mat-select>
      </mat-form-field>
    <div class="bug-resolve">
      <button 
        id="bug-button" 
        class="edit-delete cursor-pointer background-primary btn-primary"
        (click)="bugSubmit()">
        Submit
      </button>
    </div>
  </div>
  <div>
      <div class="bug-data">
        <div>Description:</div>
        <textarea rows="4" cols="60" readonly>{{ bugData.description }}</textarea>
      </div>
    <div class="bug-file">
      <div *ngFor="let file of bugData.data; let index=index;" class="for-loop">
        <!-- for video files -->
        <div *ngIf="file.type === 'video'">
          <video
            src="{{ file.fileUrl }}"
            alt=""
            width="200"
            controls="true"
          ></video>
        </div>

        <!-- for image files -->
        <div *ngIf="file.type === 'image'">
          <img src="{{ file.fileUrl }}" alt="" (click)="imageView(index)" />
        </div>

        <!-- for audio files -->
        <div *ngIf="file.type === 'audio'">
          <audio
            src="{{ file.fileUrl }}"
            alt=""
            width="200"
            controls="true"
          ></audio>
        </div>
      </div>
    </div>
  </div>
  </div>