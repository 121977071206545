import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { BibleBooksService } from 'src/app/services/bible-book/bible-book.service';
import { DialogsService } from 'src/app/services/dialog/dialog.service';
import { ProjectProfileService } from 'src/app/services/project-profile/project-profile.service';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent implements OnInit {
  adminLoggedIn: any;
  currentRole: any;
  itemSerialNumber: any;
  isDisplay: boolean = false;
  projectList: any = [];
  dataSource: any;
  role: string | any;
  search: any;
  books: any;
  displayedColumns: string[] = [
    'position',
    'projectName',
    'projectType',
    'language',
  ];
  checkAdmin: any;

  @ViewChild(MatPaginator) paginator: MatPaginator | any;

  constructor(
    private router: Router,
    private bibleBooksService: BibleBooksService,
    private projectProfileService: ProjectProfileService,
    private dialogService: DialogsService
  ) {
    this.checkAdmin = localStorage.getItem('checkAdmin');
    this.adminLoggedIn = JSON.parse(this.checkAdmin);
    this.currentRole = localStorage.getItem('role');
    // if (this.adminLoggedIn && this.currentRole !== 'Coordinator') {
    if (this.adminLoggedIn && this.currentRole !== 'Translation Manager') {
      this.displayedColumns[4] = 'settings';
    }
    this.dialogService.confirm();
  }

  async ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    });
    this.role = localStorage.getItem('role');

    // if (this.adminLoggedIn && this.currentRole !== 'Coordinator') {
    //   this.getProjectsAll();
    // } else if (this.adminLoggedIn && this.currentRole === 'Coordinator') {
    //   this.getCoordinatorProjects();
    // }
    if (this.adminLoggedIn && this.currentRole === 'SuperAdmin') {
      this.getProjectsAll();
    }

    this.itemSerialNumber = 1;

    if (this.role == 'Translation Manager') {
      this.router.navigateByUrl('performance');
      this.dialogService.closeModal();
    }
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  /****************************
   *  Filter Project
   *****************************/
  filterProjects(search: any) {
    const projectList = this.projectList.filter((projects: any) =>
      projects.projectName.toLowerCase().includes(search.toLowerCase())
    );
    this.dataSource = new MatTableDataSource(projectList);
  }

  /****************************
   *  Change page
   *****************************/
  changePage(evn: any) {
    this.itemSerialNumber = evn.pageSize * evn.pageIndex + 1;
  }

  /****************************
   *  Get Books Percentage
   *****************************/
  getBooksPercentage(project: any,language: any, type: any) {
    if (this.adminLoggedIn && this.currentRole === 'Coordinator') {
      this.router.navigateByUrl('adminDashboard');
    } else if (this.adminLoggedIn && this.currentRole !== 'Coordinator') {
      this.bibleBooksService.setLanguageTypeInfo(project,language, type);
      this.router.navigateByUrl(`books`);
    }
  }

  getProjectsAll() {
    this.projectProfileService
      .getProjects()
      .subscribe(async (projectListData) => {
        projectListData.forEach((project: any) => {
          const value = Object.keys(project.projectType).filter((key: any) => {
            if (project.projectType[key] === true || false) {
              return key;
            }
          });
          project.projectType = value.length > 1 ? value.join('/') : value;
          project.settings = 'Edit/Audio Files';
        });

        this.dialogService.closeModal();
        this.projectList = projectListData;        
        this.isDisplay = true;
        await wait();
        this.dataSource = new MatTableDataSource<void>(this.projectList);
        this.dataSource.paginator = this.paginator;
      });
  }

  getCoordinatorProjects() {
    this.projectProfileService
      .getCoordinatorProjects()
      .subscribe((projectListData) => {
        projectListData.forEach((project: any) => {
          const value = Object.keys(project.projectType).filter((key: any) => {
            if (project.projectType[key]) {
              return key;
            }
          });
          project.projectType = value.length > 1 ? value.join('/') : value;
          project.settings = 'Edit/Audio Files';
        });
        this.projectList = projectListData;
        this.dataSource = new MatTableDataSource(this.projectList);
        this.dataSource.paginator = this.paginator;
        this.dialogService.closeModal();
      });
  }
}

const wait = async () => {
  let newPromise = await new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, 100);
  });

  return newPromise;
};
