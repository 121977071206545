import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  MatTreeFlattener,
  MatTreeFlatDataSource,
} from '@angular/material/tree';
import { ActivatedRoute } from '@angular/router';
import { DialogsService } from 'src/app/services/dialog/dialog.service';
import { GraphsService } from 'src/app/services/graphs/graphs.service';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexMarkers,
  ApexYAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexLegend,
} from 'ng-apexcharts';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  tooltip: any; // ApexTooltip;
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
};

interface BookNode {
  bookName: string;
  chapters?: ChapterNode[];
}

interface ChapterNode {
  chapterName: string;
  sourceVerseName?: string[];
}

interface Language {
  majorLanguage: string;
  languageName: string;
}

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'app-user-graph',
  templateUrl: './user-graph.component.html',
  styleUrls: ['./user-graph.component.scss'],
})
export class UserGraphComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;

  displayedColumns: string[] = ['start', 'end', 'time', 'deviceType'];
  userSessionData: any;

  @ViewChild(MatPaginator) paginator: MatPaginator | any;

  userId: string | any;
  userRole: string | any;
  isShown: boolean = false;
  videoFiles = 0;
  audioFiles = 0;
  scriptFile = 0;
  file = 0;
  topLanguages: Language[] = [];
  topBooks: BookNode[] = [];
  isDisplay: boolean = false;
  userFilesData: any = [];
  userSession: any[] = [];
  itemSerialNumber: any;

  private _transformer = (
    node: BookNode | ChapterNode | string,
    level: number
  ): ExampleFlatNode => {
    if (typeof node === 'string') {
      return {
        expandable: false,
        name: node,
        level: level,
      };
    }
    return {
      expandable:
        !!(node as BookNode).chapters ||
        !!(node as ChapterNode).sourceVerseName,
      name: (node as BookNode).bookName || (node as ChapterNode).chapterName,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node: BookNode | ChapterNode) =>
      (node as BookNode).chapters ||
      (node as ChapterNode).sourceVerseName?.map((verse) => ({
        chapterName: verse,
      })) ||
      []
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(
    private activatedRoute: ActivatedRoute,
    private graphService: GraphsService,
    private dialogService: DialogsService
  ) {
    this.dialogService.confirm();
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      this.userId = params.id;
      this.userRole = params.role;
      this.getUserGraphs();
    });
    this.userSessionData.paginator = this.paginator;
  }

  getUserGraphs() {
    try {
      this.graphService.getUserGraphs(this.userId, this.userRole).subscribe(
        (response) => {
          this.audioFiles = response.filesData.audioFiles;
          this.file = response.filesData.file;
          this.videoFiles = response.filesData.videoFiles;
          this.scriptFile = response.filesData.scriptFile;
          this.topLanguages = response.filesData.topLanguages || [];
          this.topBooks = response.filesData.topBooks || [];

          if (
            response.getFilesByMonths &&
            Array.isArray(response.getFilesByMonths) &&
            response.getFilesByMonths.length > 0
          ) {
            this.userFilesData = Object.values(
              response.getFilesByMonths[new Date().getFullYear()]
            );
          }

          console.log('USer FIles', this.userFilesData);

          if (Array.isArray(this.topBooks)) {
            (this.dataSource as any).data = this.topBooks;
          } else {
            console.error('Top books data is not an array:', this.topBooks);
            this.dataSource.data = [];
          }

          this.isDisplay = true;
          this.getUserFilesByMonth();
          this.getUserSessions();
          this.dialogService.closeModal();
        },
        (error) => {
          this.isDisplay = true;
          this.dialogService.closeModal();
          console.error('Error fetching user graphs:', error);
        }
      );
    } catch (error) {
      this.isDisplay = true;
      this.dialogService.closeModal();
      console.error('Unexpected error:', error);
    }
  }

  getUserFilesByMonth() {
    this.chartOptions = {
      series: this.userFilesData,
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      title: {
        text: 'Files created by Month',
        align: 'left',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
      },
    };
  }

  getUserSessions() {
    try {
      this.graphService.getUserSessions(this.userId).subscribe(
        (response) => {
          console.log(response);
          this.userSessionData = new MatTableDataSource<void>(response);
          this.userSessionData.paginator = this.paginator;
        },
        (error) => {
          this.userSessionData = new MatTableDataSource<void>([]);
          this.userSessionData.paginator = this.paginator;
          console.error('Error fetching user session:', error);
        }
      );
    } catch (error) {}
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  changePage(evn: any) {
    this.itemSerialNumber = evn.pageSize * evn.pageIndex + 1;
  }

  formatTime(seconds: number): string {
    let hours: number = Math.floor(seconds / 3600);
    let minutes: number = Math.floor((seconds % 3600) / 60);
    let sec: number = seconds % 60;

    let hoursStr = hours > 0 ? hours + 'h ' : '';
    let minutesStr = minutes > 0 ? Number(minutes).toFixed(0) + 'm ' : '';
    let secStr = Number(sec).toFixed(0) + 's';

    return hoursStr + minutesStr + secStr;
  }
}
