<ng-container *ngIf="isDisplay">
  <div>
    <div class="card_stats">
      <div class="card">
        <div>
          <h2>Total Files</h2>
        </div>
        <div class="card_num">{{ file }}</div>
      </div>
      <div class="card">
        <div>
          <h2>Audio Files</h2>
        </div>
        <div class="card_num">{{ audioFiles }}</div>
      </div>
      <div class="card">
        <div>
          <h2>Script Files</h2>
        </div>
        <div class="card_num">{{ scriptFile }}</div>
      </div>
      <div class="card">
        <div>
          <h2>Video Files</h2>
        </div>
        <div class="card_num">{{ videoFiles }}</div>
      </div>
    </div>
  </div>

  <div class="languageBlock">
    <h2>Top Languages</h2>

    <p *ngIf="topLanguages.length == 0">Languages not available</p>

    <div *ngIf="topLanguages.length > 0">
      <div *ngFor="let language of topLanguages" class="LanguagesContainer">
        <p style="text-transform: capitalize">{{ language.majorLanguage }}</p>
        <p class="iconCss">
          <mat-icon>
            <span class="material-symbols-outlined"> trending_flat </span>
          </mat-icon>
        </p>
        <p style="text-transform: capitalize">{{ language.languageName }}</p>
      </div>
    </div>
  </div>

  <div class="languageBlock">
    <h2>Top Books</h2>

    <div *ngIf="dataSource.data.length == 0">
      <p>Books not available</p>
    </div>

    <mat-tree
      *ngIf="dataSource.data.length > 0"
      [dataSource]="dataSource"
      [treeControl]="treeControl"
    >
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
        <button
          mat-icon-button
          disabled
          style="text-transform: capitalize"
        ></button>
        {{ node.name }}
      </mat-tree-node>

      <mat-tree-node
        *matTreeNodeDef="let node; when: hasChild"
        matTreeNodePadding
        style="text-transform: capitalize"
      >
        <button
          mat-icon-button
          matTreeNodeToggle
          [attr.aria-label]="'Toggle ' + node.name"
          style="text-transform: capitalize"
        >
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
          </mat-icon>
        </button>
        {{ node.name }}
      </mat-tree-node>
    </mat-tree>
  </div>
  <div id="userFilesChart">
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [xaxis]="chartOptions.xaxis"
      [stroke]="chartOptions.stroke"
      [tooltip]="chartOptions.tooltip"
      [dataLabels]="chartOptions.dataLabels"
      [legend]="chartOptions.legend"
      [markers]="chartOptions.markers"
      [grid]="chartOptions.grid"
      [yaxis]="chartOptions.yaxis"
      [title]="chartOptions.title"
    ></apx-chart>
  </div>

  <div id="userSessionWrapper">
    <h4>User Sessions</h4>

    <mat-card *ngIf="userSessionData.data && userSessionData.data.length">
      <table
        mat-table
        [dataSource]="userSessionData"
        matSort
        class="mat-elevation-z8"
        id="userSessionTable"
      >
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef class="header-format">S.No</th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ paginator.pageIndex * paginator.pageSize + i + 1 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="start">
          <th mat-header-cell *matHeaderCellDef class="header-format">
            Start Time
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.start | date : "MMM d, y, h:mm a" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="end">
          <th mat-header-cell *matHeaderCellDef class="header-format">
            End Time
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.end | date : "MMM d, y, h:mm a" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="time">
          <th mat-header-cell *matHeaderCellDef class="header-format">Time</th>
          <td mat-cell *matCellDef="let element">
            {{ formatTime(element.time) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="deviceType">
          <th mat-header-cell *matHeaderCellDef class="header-format">
            Device Type
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            style="text-transform: capitalize"
          >
            {{ element.deviceType }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator
        (page)="changePage($event)"
        class="mat-paginator-outer-container"
        [pageSizeOptions]="[5, 10, 20, 30, 40, 50]"
        [pageSize]="5"
        showFirstLastButtons
      ></mat-paginator>
    </mat-card>

    <mat-card
      class="no-projects"
      *ngIf="userSessionData.data && userSessionData.data.length < 1"
    >
      <h3>User Session Not Found!</h3>
    </mat-card>
  </div>
</ng-container>
