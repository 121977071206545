<mat-card>
  <div class="main-project-header">
    <h2 class="text-primary dashboard">TimeLine Request List</h2>
  </div>
  <div>
    <div class="search background-primary searchbar">
      <mat-label> Search </mat-label>
      <input type="search" placeholder="Search Projects" class="searchBar" />
    </div>
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef class="header-format">S.No</th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ itemSerialNumber + i }}
        </td>
      </ng-container>
      <ng-container matColumnDef="projectName">
        <th mat-header-cell *matHeaderCellDef class="header-format">
          Project Name
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ element.projectName.projectName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="userName">
        <th mat-header-cell *matHeaderCellDef class="header-format">
          User Name
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ element.userName.firstName }} {{ element.userName.lastName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="language">
        <th mat-header-cell *matHeaderCellDef class="header-format">
          Language
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ element.language.language }}
        </td>
      </ng-container>
      <ng-container matColumnDef="stageName">
        <th mat-header-cell *matHeaderCellDef class="header-format">
          Stage Name
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ element.stageName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="bookName">
        <th mat-header-cell *matHeaderCellDef class="header-format">
          Book Name
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ element.bookName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="BookNoOfDays">
        <th mat-header-cell *matHeaderCellDef class="header-format">
          Book Number of Days
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ element.BookNoOfDays }}
        </td>
      </ng-container>
      <ng-container matColumnDef="chapterName">
        <th mat-header-cell *matHeaderCellDef class="header-format">
          Chapter Name
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ element.chapterName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="ChapterNoOfDays">
        <th mat-header-cell *matHeaderCellDef class="header-format">
          Chapter Number of Days
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ element.ChapterNoOfDays }}
        </td>
      </ng-container>
      <ng-container matColumnDef="approval">
        <th mat-header-cell *matHeaderCellDef class="header-format">Action</th>
        <td mat-cell *matCellDef="let element; let i = index">
          <button
            [disabled]="element.approval"
            [style.background-color]="
              element.approval == true
                ? 'gray'
                : 'background-primary btn-primary'
            "
            class="edit-delete cursor-pointer background-primary btn-primary"
            (click)="approvalTimeLineExtend(element)"
          >
            Approve
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <div>
      <mat-paginator
        (page)="changePage($event)"
        class="mat-paginator-outer-container"
        [pageSizeOptions]="[5, 10, 20, 30, 40, 50]"
        [pageSize]="20"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</mat-card>
<!-- <mat-card class="no-projects" *ngIf="timelineRequest && timelineRequest.length < 1">
    <h3>Timeline Request Not Found!</h3>
</mat-card> -->
