<div class="flex items-center sm-w-100">
    <div [formGroup]="countryCordinatorsGroup" class="grid grid-cols-3  sm-grid-cols-1 grill sm-w-100">
        <mat-form-field color="accent" class="formfield">
            <mat-label>CC </mat-label>
            <mat-select (selectionChange)="setValue($event.value)" formControlName="countryCoordinator" required>
                <mat-option *ngFor="let countryCoordinator of countryCordinators" [value]="countryCoordinator">
                    {{countryCoordinator.firstName}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="countryCordinatorsGroup.controls.countryCoordinator.errors?.required">Please select cc</mat-error>
        </mat-form-field>
        <mat-form-field color="accent" class="formfield mx-12">
            <input matInput type="text" placeholder="CC Email" [(ngModel)]="email"
                [ngModelOptions]="{standalone: true}" disabled>
        </mat-form-field>
        <mat-form-field color="accent" class="formfield">
            <input matInput type="text" placeholder="CC Number" [(ngModel)]="phNo"
                [ngModelOptions]="{standalone: true}" disabled>
        </mat-form-field>
    </div>
    <mat-icon (click)="showUser()"><span class="material-symbols-outlined"> av_timerss </span></mat-icon>
</div>