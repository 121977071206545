<mat-card class="mat-card sign-up font-primary">
    <mat-card-header class="background-primary border-top-5">
        <mat-card-title>Edit User Details</mat-card-title>
    </mat-card-header>
    <form [formGroup]="editUserDetails">
        <mat-card-content>
            <mat-form-field color="accent" class="first-last">
                <input matInput placeholder="First Name" formControlName="firstName" required>
                <mat-error *ngIf="editUserDetails.controls.firstName.errors?.required ||
                           editUserDetails.controls.firstName.errors?.pattern">
                    Please enter first name
                </mat-error>
            </mat-form-field>
            <mat-form-field color="accent" class="first-last">
                <input matInput placeholder="Last Name" formControlName="lastName" required>
                <mat-error *ngIf="editUserDetails.controls.lastName.errors?.required ||
                editUserDetails.controls.lastName.errors?.pattern">
                    Please enter last name
                </mat-error>
            </mat-form-field>
            <mat-form-field color="accent" class="first-last">
                <input matInput placeholder="Email" formControlName="email" required>
                <mat-error *ngIf="editUserDetails.controls.email.errors?.required">Please enter a valid email
                </mat-error>
            </mat-form-field>
            <mat-form-field color="accent">
                <input type="number" matInput placeholder="Mobile Number" formControlName="mobileNumber">
                <mat-error *ngIf="editUserDetails.controls.mobileNumber.errors?.required ||
                 editUserDetails.controls.mobileNumber.errors?.pattern">Please enter a valid Mobile
                    Number
                </mat-error>
            </mat-form-field>
            <div class="button-row">
                <button mat-raised-button [ngClass]="{'btn-primary rounded-sm': editUserDetails.valid ,'create-btn': true}"
                    [disabled]="!editUserDetails.valid" (click)="updateUserDetails()">Update User
                </button>
                <span class="px-2"></span>
                <button mat-raised-button class="btn-primary rounded-sm mt-2" [ngClass]="{'create-btn': true}"
                    [routerLink]="['/user-details']">Cancel
                </button>
            </div>
        </mat-card-content>
    </form>
</mat-card>