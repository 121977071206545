<ng-container *ngIf="isShown">
  <div>
    <div class="card_stats">
      <div class="card">
        <div>
          <h2>Projects</h2>
        </div>
        <div class="card_num">
          {{ projectCount.audioAndText }} | {{ projectCount.signLanguage }} |
          {{ projectCount.jesusFilm }}
        </div>
      </div>
      <div class="card">
        <div>
          <h2>Languages</h2>
        </div>
        <div class="card_num">
          {{ languageCount }}
        </div>
      </div>
      <div class="card">
        <div>
          <h2>Sources Files</h2>
        </div>
        <div class="card_num">
          {{ sourceFileCounts.scriptFiles }} |
          {{ sourceFileCounts.audioFiles }} |
          {{ sourceFileCounts.videoFiles }}
        </div>
      </div>
      <div class="card">
        <div>
          <h2>Users</h2>
        </div>
        <div class="card_num">{{ usersCounts }}</div>
      </div>
    </div>

    <div id="chart">
      <apx-chart
        [series]="fileChartOptions.series"
        [chart]="fileChartOptions.chart"
        [dataLabels]="fileChartOptions.dataLabels"
        [plotOptions]="fileChartOptions.plotOptions"
        [yaxis]="fileChartOptions.yaxis"
        [legend]="fileChartOptions.legend"
        [fill]="fileChartOptions.fill"
        [stroke]="fileChartOptions.stroke"
        [tooltip]="fileChartOptions.tooltip"
        [xaxis]="fileChartOptions.xaxis"
        [title]="fileChartOptions.title"
      ></apx-chart>
    </div>

    <div id="topUsers">
      <h2>Top Users</h2>
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
          <button mat-icon-button disabled></button>
          {{ node.name }}
        </mat-tree-node>
        <mat-tree-node
          *matTreeNodeDef="let node; when: hasChild"
          matTreeNodePadding
          matTreeNodeToggle
          [cdkTreeNodeTypeaheadLabel]="node.name"
        >
          <button
            mat-icon-button
            matTreeNodeToggle
            [attr.aria-label]="'Toggle ' + node.name"
          >
            <mat-icon class="mat-icon-rtl-mirror">
              {{
                treeControl.isExpanded(node) ? "expand_more" : "chevron_right"
              }}
            </mat-icon>
          </button>
          {{ node.name }}
        </mat-tree-node>
      </mat-tree>
    </div>
  </div>
</ng-container>
