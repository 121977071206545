import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexStroke,
  ApexGrid,
} from 'ng-apexcharts';
import { DialogsService } from 'src/app/services/dialog/dialog.service';
import { GraphsService } from 'src/app/services/graphs/graphs.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
};

interface Node {
  name: string;
  children?: Node[];
}

const FLAT_TREE_DATA: Node[] = [];

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  projectCount = { signLanguage: 0, jesusFilm: 0, audioAndText: 0 };
  languageCount: number = 0;
  usersCounts: number = 156;
  sourceFileCounts = { audioFiles: 0, scriptFiles: 0, videoFiles: 0 };
  filesCompletedByMonths = [];

  private _transformer = (node: Node, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  public isShown = false;
  @ViewChild('chart') chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;

  @ViewChild('chart') usersByCountry: ChartComponent | any;
  public usersByCountryOptions: Partial<ChartOptions> | any;

  @ViewChild('chart') filesChart: ChartComponent | any;
  public fileChartOptions: Partial<ChartOptions> | any;

  @ViewChild('chart') userRolesChart: ChartComponent | any;
  public sourceFilesOptions: Partial<ChartOptions> | any;

  @ViewChild('chart') fileTypesChart: ChartComponent | any;
  public fileTypesChartOptions: Partial<ChartOptions> | any;

  users: any[] = [];

  constructor(
    private graphService: GraphsService,
    private dialogService: DialogsService
  ) {
    this.dataSource.data = FLAT_TREE_DATA;
    this.dialogService.confirm();
  }

  ngOnInit(): void {
    this.getGraphDetails();
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  getGraphDetails() {
    this.graphService.getDashboardGraphs().subscribe(
      (response) => {
        console.log(response);
        this.languageCount = response.languageData;
        this.usersCounts = response.users;
        this.sourceFileCounts = response.sourceFiles;
        this.projectCount = response.project;

        this.users = response.topUsersByFiles;

        const transformedUsersData = this.transformTopUsersData(this.users);
        this.dataSource.data = transformedUsersData;
        let currentYearFiles = response.filesCompletedByMonths.find(
          (item: any) => item.year == new Date().getFullYear()
        );

        if (currentYearFiles) {
          this.filesCompletedByMonths = currentYearFiles.data;
          this.fileCompletedByMonthCharts(currentYearFiles.data);
        }

        this.dialogService.closeModal();
        this.isShown = true;
      },
      (error) => {
        console.error('Error fetching dashboard graphs:', error);
        this.dialogService.closeModal();
        this.isShown = true;
      }
    );
  }

  transformTopUsersData(users: any[]): Node[] {
    return users.map((user) => {
      return {
        name: `${user.user.firstName} ${user.user.lastName}    (${user.user.email})    (${user.user.role})`,
        children: [
          { name: `Total Files: ${user.fileCount}` },
          { name: `Back Translate Files: ${user.backTranslateFiles.total}` },
          { name: `Draft Files: ${user.draftFiles.total}` },
          { name: `Master Record Files: ${user.masterRecordFiles.total}` },
        ],
      };
    });
  }

  usersByMonthsChart() {
    this.chartOptions = {
      series: [
        {
          name: 'MTT',
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 62, 68, 50],
        },
        {
          name: 'Facilitator',
          data: [42, 53, 58, 54, 62, 60, 64, 59, 65, 61, 67, 48],
        },
        {
          name: 'CIT',
          data: [46, 56, 55, 57, 60, 59, 62, 61, 68, 63, 66, 49],
        },
        {
          name: 'Consultant',
          data: [43, 54, 56, 55, 63, 59, 61, 62, 67, 60, 64, 46],
        },
        {
          name: 'Technician',
          data: [45, 57, 59, 53, 60, 57, 66, 64, 69, 62, 68, 52],
        },
        {
          name: 'Coordinator',
          data: [47, 52, 54, 58, 61, 60, 65, 62, 67, 63, 65, 44],
        },
        {
          name: 'Translation Manager',
          data: [48, 53, 56, 55, 59, 61, 63, 60, 68, 64, 66, 47],
        },
      ],
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
        events: {
          selection: function (chartContext: any, { xaxis, yaxis }: any) {
            console.log('ChartContext', chartContext);
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      title: {
        text: 'Users By Months',
        align: 'left',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
      },
    };
  }

  usersByCountriesChart() {
    this.usersByCountryOptions = {
      series: [
        {
          name: 'MTT',
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 62, 68, 50],
        },
        {
          name: 'Facilitator',
          data: [42, 53, 58, 54, 62, 60, 64, 59, 65, 61, 67, 48],
        },
        {
          name: 'CIT',
          data: [46, 56, 55, 57, 60, 59, 62, 61, 68, 63, 66, 49],
        },
        {
          name: 'Consultant',
          data: [43, 54, 56, 55, 63, 59, 61, 62, 67, 60, 64, 46],
        },
        {
          name: 'Technician',
          data: [45, 57, 59, 53, 60, 57, 66, 64, 69, 62, 68, 52],
        },
        {
          name: 'Coordinator',
          data: [47, 52, 54, 58, 61, 60, 65, 62, 67, 63, 65, 44],
        },
        {
          name: 'Translation Manager',
          data: [48, 53, 56, 55, 59, 61, 63, 60, 68, 64, 66, 47],
        },
      ],
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      title: {
        text: 'Users By Countries',
        align: 'left',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
      },
    };
  }

  fileCompletedByMonthCharts(data: any) {
    this.fileChartOptions = {
      series: data,
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      title: {
        text: 'Files created by Month',
        align: 'left',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
      },
    };
  }

  userByRolePieChart() {
    this.sourceFilesOptions = {
      series: [44, 55, 13],
      chart: {
        type: 'donut',
      },
      labels: ['Audio', 'Video', 'Script'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 2000,
              outerHeight: 5000,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
  }

  fileCompletedPieChart() {
    this.fileTypesChartOptions = {
      series: [44, 55, 13, 43],
      chart: {
        type: 'donut',
      },
      labels: ['Source', 'Draft', 'Back Translate', 'Master Record'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
  }
}
