import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PopUpModalComponent } from 'src/app/components/pop-up-modal/pop-up-modal.component';
import { ProjectDashboardComponent } from 'src/app/components/project-dashboard/project-dashboard.component';
import { BibleBooksService } from 'src/app/services/bible-book/bible-book.service';
import { DialogsService } from 'src/app/services/dialog/dialog.service';
import { LanguageService } from 'src/app/services/language/language.service';


@Component({
    selector: 'app-testment-books',
    templateUrl: './delete-testment-books.component.html',
    styleUrls: ['./delete-testment-books.component.scss']
  })

export class DeleteTestmentBooksComponent implements OnInit {
    books: FormGroup | any;
    tabInfo: any;
    currentTab = 'Old Testament';
    selectedIndex = 0;
    dataSource: any;
    showChapter = false;
    showTabs = false;
    showFiles = false;
    booksData: any;
    fileType: any[] = [{ type: 'audio' }, { type: 'note' }, { type: 'script' }, { type: 'video' }];
    displayedColumns: string[] = ['BookName'];
    displayedColumnsNames: string[] = ['ChapterName'];
    displayFileColums: string[] = ['FileName'];
    currentBookName: any;
    chaptersData: any;
    currentChapterName: any;
    sourceLanguagesData: any;
    bookDataOld: Array<any> = [];
    bookDataNew: Array<any> = [];
    fileData: any;
    bookDataNotFound: boolean | any;
    itemSerialNumber: any;
    isDeleted:boolean=false;
    hideDelete:boolean=true;
  
    @ViewChild(MatPaginator) paginator: MatPaginator | any;
    sourceLanguage: any;
  
  
    constructor(private formBuilder: FormBuilder,
      private dialog: MatDialog,
      public dialogsService: DialogsService,
      private languageService: LanguageService,
      private bibleBooksService: BibleBooksService) { }
  
    ngOnInit(): void {
      this.createFormControls();
      this.getSourceLanguages();
      this.itemSerialNumber = 1;
    }
  
    createFormControls() {
      this.books = new FormGroup({
        language: new FormControl('', Validators.required),
        type: new FormControl('', Validators.required)
      });
    }
  
    async booksForm() {
      this.clearAllTabsData();
      const language = this.books.value.language;
      const type = this.books.value.type;
      this.sourceLanguage=language;
      this.booksData = await this.bibleBooksService.getBooksByLanguage(language, type).toPromise();      
      if (!this.booksData.length) {
        this.dataSource = new MatTableDataSource([]);
        this.showTabs = false;
        this.bookDataNotFound = true;
        return;
      }
      this.showTabs = true;
      this.bookDataNotFound = false;
      this.getTestamentBooks();
      this.isDeleted=true;
    }
  
    getTestamentBooks() {
      this.booksData.forEach((booksObj: any) => {
        if (booksObj._id === 'old') {
          this.bookDataOld = booksObj.books;
          this.dataSource = new MatTableDataSource(this.bookDataOld);
          this.dataSource.paginator = this.paginator;
        } else {
          this.bookDataNew = booksObj.books;
          this.dataSource.paginator = this.paginator;
        }
      });
    }
  
    deleteSourceContent(filter: any){
      this.bibleBooksService.getSourceProjectList(filter.sourceLanguage).subscribe(async (response) => {
        await this.dialogsService.closeModal();
        filter['data']=await response;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = filter;
        dialogConfig.height = "83%";
        dialogConfig.width = "100%";
        const dialogRef = this.dialog.open(ProjectDashboardComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(async (data) => {
          if (data.status=="ok") {
            await this.dialogsService.closeModal();
            this.dialog.open(PopUpModalComponent, {
              data: {
                message: data.message
              }
            })
            if(this.currentBookName==undefined){
              this.createFormControls()
              this.getSourceLanguages()
            }
            this.booksForm();
            this.isDeleted=false;
          }else{
            this.isDeleted=true;
          }
        });
      });
    }
  
    deleteSourceProjectList() {
      this.dialogsService.confirm();
      let filter={
        sourceLanguage: this.books.controls.language.value,
      }
      this.deleteSourceContent(filter);
    }
  
    deleteSourceProjectBookWise(element: any){
      this.dialogsService.confirm();
      let filter={
        sourceLanguage: this.books.controls.language.value,
        fileType:this.books.value.type.toLowerCase(),
        bookName:element,
      }
      this.deleteSourceContent(filter);    
    }
  
    deleteSourceProjectChapterWise(element: any){
      this.dialogsService.confirm();
      let filter={
        sourceLanguage: this.books.controls.language.value,
        fileType:this.books.value.type.toLowerCase(),
        bookName: this.currentBookName,
        chapterName:element,        
      }
      this.deleteSourceContent(filter);
    }
  
    goToBooks() {
      const previousState = this.bibleBooksService.getPreviousState();
      this.paginator.pageIndex = 0;
      if (previousState === 'BooksScreen') {
        this.showChapter = false;
        this.showFiles = false;
        (this.currentTab === 'Old Testament') ? this.setMatData(this.bookDataOld) : this.setMatData(this.bookDataNew);
      } else if (previousState === 'chapterScreen') {
        this.showChapter = true;
        this.showFiles = false;
        this.setMatData(this.chaptersData);
        this.bibleBooksService.setPreviousState('BooksScreen');
      }
    }
  
    setMatData(data: any) {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
    }
  
    async getChapters(bookName: any) {
      this.bibleBooksService.setPreviousState('BooksScreen');
      this.paginator.pageIndex = 0;
      this.showChapter = true;
      this.currentBookName = bookName;
      const language = this.books.value.language;
      const type = this.books.value.type;
      const filter = { bookName: this.currentBookName, language: language, type: type, targetType: 'source' };
      this.chaptersData = await this.bibleBooksService.getChaptersByBookName(filter).toPromise();
      this.dataSource = new MatTableDataSource(this.chaptersData);
      this.dataSource.paginator = this.paginator;
    }
  
  
    async getFiles(chapterName: any, bookName: any) {
      this.bibleBooksService.setPreviousState('chapterScreen');
      this.paginator.pageIndex = 0;
      this.currentChapterName = chapterName;
      const language = this.books.value.language;
      const type = this.books.value.type;
      const chapter = chapterName;
      const filter = { bookName: bookName, language: language, type: type, targetType: 'source', chapterName: chapter };
      this.fileData = await this.bibleBooksService.getFilesByChapter(filter).toPromise();
      this.dataSource = new MatTableDataSource(this.fileData);
      this.dataSource.paginator = this.paginator;
      this.showChapter = false;
      this.showFiles = true;
  
    }
  
    getSourceLanguages() {
      this.languageService.getSourcLanguages().subscribe((userData) => {
        this.sourceLanguagesData = this.alphaOrder(userData, 'language');
      });
    }
  
    alphaOrder(list: any, fieldName: any) {
      return list.sort((a: any, b: any) => {
        if (a[fieldName] > b[fieldName]) return 1;
        else if (a[fieldName] < b[fieldName]) return -1;
        else return 0;
      });
    }
  
    showTabsCard() {
      this.isDeleted=false;
      this.showTabs = false;
    }
  
    clearAllTabsData() {
      this.bookDataOld = [];
      this.bookDataNew = [];
      this.chaptersData = [];
      this.fileData = [];
      this.dataSource = new MatTableDataSource([]);
      this.showChapter = false;
      this.showTabs = false;
      this.showFiles = false;
      this.currentTab = 'Old Testament';
      this.selectedIndex = 0;
    }
  
    setTabInfo($event: any) {
      this.paginator.pageIndex = 0;
      this.itemSerialNumber = 1;
      this.tabInfo = $event;
      this.selectedIndex = $event.index;
      this.currentTab = this.tabInfo.tab.textLabel;
      if (this.currentTab === 'Old Testament') {
        this.dataSource = new MatTableDataSource(this.bookDataOld);
        this.dataSource.paginator = this.paginator;
      } else {
        this.dataSource = new MatTableDataSource(this.bookDataNew);
        this.dataSource.paginator = this.paginator;
      }
      this.showChapter = false;
      this.showFiles = false;
    }
}