<mat-card class="parentCard">
    <mat-card-header class="font-primary background-primary border-top-5">
        <mat-card-title>Files Dashboard</mat-card-title>
    </mat-card-header>
    <form [formGroup]="books">
        <mat-card-content class="parentContent">
            <div class="grid grid-cols-3 grill sm-grid-cols-1">
                <mat-form-field color="accent" class="formfield">
                    <mat-label>Language</mat-label>
                    <mat-select formControlName="language" (selectionChange)="showTabsCard()" required>
                        <mat-option *ngFor="let language of sourceLanguagesData"
                                    [value]="language.language">{{language.language}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="books.controls.language.errors?.required">
                        Please select a language
                    </mat-error>
                </mat-form-field>
                <mat-form-field color="accent">
                    <mat-label>Type</mat-label>
                    <mat-select formControlName="type" (selectionChange)="showTabsCard()" required>
                        <mat-option *ngFor="let type of fileType" [value]="type.type">{{type.type}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="books.controls.type.errors?.required">
                        Please select type
                    </mat-error>
                </mat-form-field>
                <div class="button-row flex">
                    <button mat-raised-button [disabled]="!books.valid" [ngClass]="{'btn-primary px-4': books.valid}"
                            (click)="booksForm()">
                        Get Books
                    </button>
                    <!-- <div *ngIf="hideDelete==true">
                        <button mat-raised-button class="ml-1" [disabled]="!isDeleted" (click)="deleteSourceProjectList()"
                        [ngClass]="{'btn-primary px-4':isDeleted}">Delete</button>
                    </div> -->
                </div>
            </div>
        </mat-card-content>
    </form>
    <div *ngIf="bookDataNotFound">
        <h4 class="no-data">No Data Found</h4>
    </div>
    <div class="tab-section" *ngIf="showTabs">
        <div>
            <mat-tab-group animationDuration="0ms" mat-align-tabs="center"
                           [selectedIndex]="selectedIndex" (selectedTabChange)="setTabInfo($event)">
                <mat-tab label="Old Testament">
                    <ng-container *ngTemplateOutlet="Testament">
                    </ng-container>
                </mat-tab>
                <mat-tab label="New Testament">
                    <ng-container *ngTemplateOutlet="Testament">
                    </ng-container>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div [hidden]="dataSource.data.length <= 5">
            <mat-card>
                <mat-paginator 
                class="mat-paginator-outer-container"
                [pageSizeOptions]="[5,10,20,30,40,50]"
                [pageSize]="20"
                showFirstLastButtons></mat-paginator>
            </mat-card>
        </div>
    </div>
    <ng-template #Testament>
        <div *ngIf="showChapter || showFiles" class="backIcon"><span matTooltip="Back to Books" (click)="goToBooks()"><mat-icon>arrow_back</mat-icon></span>
        </div>
        <mat-card class="user-section"
                  *ngIf="(!showChapter && !showFiles) && ((bookDataOld.length && currentTab === 'Old Testament') || (bookDataNew.length && currentTab === 'New Testament'))">
            <mat-card-header>
                <mat-card-title class="text-black mr-1">{{currentTab}} Books</mat-card-title>
            </mat-card-header>
            <div class="language-table">
                <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8"
                       *ngIf="(bookDataOld && bookDataOld.length ) || (bookDataNew && bookDataNew.length ) ">
                    <ng-container matColumnDef="BookName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-format">Book Name</th>
                        <td mat-cell *matCellDef="let element" class="data-format bk-testament">
                            <span class="btn-delete" (click)="getChapters(element)">{{element}}</span>
                            <!-- <button *ngIf="hideDelete==true" mat-raised-button class="btn-delete" (click)="deleteSourceProjectBookWise(element)">Delete</button> -->
                            </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </mat-card>
        <mat-card *ngIf="showChapter" class="user-section">
            <mat-card-header>
                <mat-card-title  class="text-black capitalize">{{currentBookName}} --> Chapters</mat-card-title>
            </mat-card-header>
            <div class="language-table">
                <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                    <ng-container matColumnDef="ChapterName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-format">Chapter Name</th>
                        <td mat-cell *matCellDef="let element" class="data-format bk-testament">
                            <span class="btn-delete" (click)="getFiles(element, currentBookName)">{{element}}</span>
                            <!-- <button mat-raised-button *ngIf="hideDelete==true" (click)="deleteSourceProjectChapterWise(element)">Delete</button> -->
                            </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsNames; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsNames;"></tr>
                </table>
            </div>
        </mat-card>
        <mat-card *ngIf="showFiles" class="user-section">
            <mat-card-header>
                <mat-card-title class="text-black capitalize">{{currentChapterName}} --> Files</mat-card-title>
            </mat-card-header>
            <div class="language-table">
                <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                    <ng-container matColumnDef="FileName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-format">File Name</th>
                        <td mat-cell *matCellDef="let element" class="data-format bk-testament">
                            <span class="btn-delete">{{element.fileName}}</span>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayFileColums; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayFileColums;"></tr>
                </table>
            </div>
        </mat-card>
        <mat-card class="no-users"
                  *ngIf="(!showChapter && !showFiles) && ((bookDataOld.length < 1 && currentTab === 'Old Testament') || (bookDataNew.length < 1 && currentTab === 'New Testament'))">
            <h3>Books Not Found!</h3>
        </mat-card>
    </ng-template>
</mat-card>
