<div class="close-button">
    <button
      class="btn-pos" 
      mat-mini-fab (click)="closeModal()"
      color="warn"
      aria-label="Example icon button with a filter list icon">
    <mat-icon>close</mat-icon>
  </button>
  </div>
    <mat-card>
        <div class="main-project-header">
            <h2 class="text-primary dashboard">Source Details</h2>
        </div>
        <div *ngIf="tableData.length;else other_content" >
            <p class="flex justify-content">The source {{sourceLanguage}} is mapped to the project details</p></div>
            <ng-template #other_content> <p class="flex justify-content">The Source {{sourceLanguage}} is not mapped to the project details</p> </ng-template>
            <mat-card-header *ngIf="tableData.length!=0" class="font-primary background-primary border-top-5">
                <mat-card-title>Project Details</mat-card-title>
            </mat-card-header>
        <div [hidden]="!(tableData && tableData.length)">
            <!-- <div class="search background-primary searchbar">
                <mat-label>
                    Search
                </mat-label>
                <input type="search" placeholder="Search" class="searchBar" [(ngModel)]="search"
                    (ngModelChange)="filterProjects(search)"/>
            </div> -->
            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8"
                *ngIf="tableData && tableData.length">
                <ng-container matColumnDef="position">
                    <th mat-header-cell *matHeaderCellDef class="header-format"> S.No</th>
                    <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}}</td>
                </ng-container>
                <ng-container matColumnDef="projectName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-format">Name</th>
                    <td mat-cell *matCellDef="let element" class="data-format "> {{element.projectName}}</td>
                </ng-container>
                <ng-container matColumnDef="language">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-format">Target Language</th>
                    <td mat-cell *matCellDef="let element" class="data-format " >{{element.projectLanguage}}</td>
                </ng-container>
                <!-- <ng-container matColumnDef="bookName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-format">Book Name</th>
                    <td mat-cell *matCellDef="let element" class="data-format " >{{element.bookName}}</td>
                </ng-container> -->
                <ng-container matColumnDef="draft">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-format">Draft</th>
                    <td mat-cell *matCellDef="let element" class="data-format " >{{element.draft}}</td>
                </ng-container>
                <ng-container matColumnDef="upload">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-format">Upload</th>
                    <td mat-cell *matCellDef="let element" class="data-format " >{{element.upload}}</td>
                </ng-container>
                <!-- <ng-container matColumnDef="masterRecord">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-format">Master Record</th>
                    <td mat-cell *matCellDef="let element" class="data-format " >{{element.masterRecord}}</td>
                </ng-container>
                <ng-container matColumnDef="consultantCheck">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-format">Consultant Check</th>
                    <td mat-cell *matCellDef="let element" class="data-format " >{{element.consultantCheck}}</td>
                </ng-container>
                <ng-container matColumnDef="communityCheck">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-format">Community Check</th>
                    <td mat-cell *matCellDef="let element" class="data-format " >{{element.communityCheck}}</td>
                </ng-container>
                <ng-container matColumnDef="backTranslation">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-format">Back Translation</th>
                    <td mat-cell *matCellDef="let element" class="data-format " >{{element.backTranslation}}</td>
                </ng-container>
                <ng-container matColumnDef="publishing">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-format">Publishing</th>
                    <td mat-cell *matCellDef="let element" class="data-format " >{{element.publishing}}</td>
                </ng-container>
                <ng-container matColumnDef="teamCheck">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-format">Team Check</th>
                    <td mat-cell *matCellDef="let element" class="data-format " >{{element.teamCheck}}</td>
                </ng-container> -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div [hidden]="tableData && tableData.length <=5">
                <mat-paginator 
                (page)="changePage($event)" 
                class="mat-paginator-outer-container"
                [pageSizeOptions]="[5,10,20,30,40,50]" 
                [pageSize]="5"
                showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </mat-card>
    <mat-card class="no-projects" *ngIf="tableData && tableData.length < 1">
        <mat-card-header class="font-primary background-primary border-top-5">
            <mat-card-title>Project Details</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <h3>Projects Not Found!</h3>
        </mat-card-content>
    </mat-card>

    <div class="btn-card">
        <div class="mt-1">
            <div class="btn-pdc btn-action">Do you want to delete the source?</div>
        </div>
        <div class="btn-action">
            <button mat-button class="btn1" (click)="delete()" value="ok">ok</button>
            <button mat-button class="btn1" (click)="closeModal()">cancel</button>
        </div>
        </div>