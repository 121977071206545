//checking jenking build
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserDataService } from 'src/app/services/user-data/user-data.service';

@Component({
  selector: 'app-confirmation-alert',
  templateUrl: './confirmation-alert.component.html',
  styleUrls: ['./confirmation-alert.component.scss']
})
export class ConfirmationAlertComponent implements OnInit {

  currentTab: any;
  isProjectList: any;
  selectedTabIndex: any;
  ProjectData: any;
  @Input() user: any;

  constructor(public dialogRef: MatDialogRef<ConfirmationAlertComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationAlertComponent,
    public userDataService: UserDataService) {
    this.user = data.user;
    this.currentTab = data.currentTab;
    this.selectedTabIndex = data.selectedTabIndex;
    this.isProjectList = data.isProjectList;
    this.ProjectData = data.ProjectData;
  }

  ngOnInit(): void {
    console.log('user', this.user)
  }

  closeModel() {
    this.dialogRef.close();
  }

  deleteUser(userData: any) {
    console.log("Delete User", userData)
    const deletedUser = this.userDataService.deleteUser(userData._id).subscribe((data: any) => {
      if (data.value) {
        this.dialogRef.close();
        this.userDataService.setTabInfo(this.currentTab, this.selectedTabIndex);
      }
    });
  }

}
