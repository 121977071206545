<form [formGroup]="performanceProfile">
  <ng-container *ngIf="isDisplay">
    <mat-card class="mat-card performance">
      <mat-card-header class="background-primary border-top-5">
        <mat-card-title class="font-primary">Performance</mat-card-title>
      </mat-card-header>

      <mat-content>
        <div class="px-2">
          <div class="firstRow mx-2">
            <div class="col" id="country-select">
              <mat-icon mat-card-avatar>{{ country.flag }}</mat-icon>
              <mat-form-field class="country-select-option">
                <mat-select
                  formControlName="countryName"
                  (selectionChange)="loadCountryDetail($event.value)"
                >
                  <mat-option
                    [value]="country.name.common"
                    spellcheck="false"
                    >{{ country.name.common }}</mat-option
                  >
                  <mat-option
                    *ngFor="let name of countryOptionList"
                    [value]="name"
                  >
                    {{ name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="flex search-select-date">
              <div class="start-end-date">
                <mat-form-field class="matformfield date-form">
                  <input
                    matInput
                    [matDatepicker]="startdate"
                    placeholder="Start-date"
                    formControlName="startDate"
                    required
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="startdate"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #startdate></mat-datepicker>
                  <mat-error
                    *ngIf="
                      performanceProfile.controls.startDate.errors?.required
                    "
                    >Please select date
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="matformfield1 mx-2 date-form">
                  <input
                    matInput
                    [matDatepicker]="endDate"
                    placeholder="End-date"
                    formControlName="endDate"
                    (dateChange)="dateValidation()"
                    required
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="endDate"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #endDate></mat-datepicker>
                  <mat-error
                    *ngIf="performanceProfile.controls.endDate.errors?.required"
                    >Please select date
                  </mat-error>
                  <mat-hint style="color: #031832" *ngIf="dateError"
                    >Please select date greater than start date
                  </mat-hint>
                </mat-form-field>
              </div>
              <div class="flex items-center">
                <input
                  type="search"
                  class="searchBar search-input-option"
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
          <div class="subHead text flex items-center justify-between mx-2">
            <span class="fontStyle">Dashboard</span>
            <button
              mat-raised-button
              type="button"
              class="mr-3 btn-primary rounded-sm text-white flex"
              (click)="getPerformanceData()"
            >
              Go
            </button>
          </div>
          <div class="flex timeLineStyle">
            <div class="orient"><span class="">Selection Criteria</span></div>
            <div class="w-100 my-4">
              <mat-card class="py-4">
                <div class="grid grid-cols-4 mx-2 sm-grid-cols-1">
                  <div class="">
                    <div>
                      <mat-form-field class="sm-w-100">
                        <mat-label>Project</mat-label>
                        <mat-select
                          formControlName="projectName"
                          (selectionChange)="projectLanguages($event)"
                        >
                          <mat-option
                            *ngFor="let data of projectDetail"
                            [value]="data"
                            >{{ data.projectName }}
                          </mat-option>
                        </mat-select>
                        <mat-error
                          *ngIf="
                            performanceProfile.controls.projectName.errors
                              ?.required
                          "
                          >Please enter project name
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="">
                    <div>
                      <mat-form-field class="sm-w-100">
                        <mat-label>Language</mat-label>
                        <mat-select
                          formControlName="language"
                          (selectionChange)="getBookName($event)"
                        >
                          <mat-option
                            *ngFor="let data of languageName"
                            [value]="data"
                            >{{ data }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="">
                    <div *ngIf="isBook">
                      <mat-form-field class="sm-w-100">
                        <mat-label>Books</mat-label>
                        <mat-select
                          formControlName="book"
                          (selectionChange)="getChapters($event)"
                        >
                          <mat-option
                            *ngFor="let data of bookName"
                            [value]="data"
                            >{{ data }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="">
                    <div *ngIf="isChapter">
                      <mat-form-field class="sm-w-100">
                        <mat-label>Chapters</mat-label>
                        <mat-select
                          formControlName="chapter"
                          (selectionChange)="filterGoItems($event)"
                        >
                          <!-- <ng-container *ngFor="let data of chaptersList"> -->
                          <mat-option
                            *ngFor="let data of chaptersList"
                            [value]="data"
                            >{{ data }}</mat-option
                          >
                          <!-- </ng-container> -->
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="user-selection">
                    <div>
                      <mat-form-field class="sm-w-100">
                        <mat-label>MTT</mat-label>
                        <mat-select
                          formControlName="mttUser"
                          (selectionChange)="filterGoItems($event)"
                        >
                          <mat-option
                            *ngFor="let data of mttUsers"
                            [value]="data.id"
                            >{{ data.firstName + " " + data.lastName }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="user-selection">
                    <mat-form-field class="sm-w-100">
                      <mat-label>Facilitator</mat-label>
                      <mat-select
                        formControlName="faciUser"
                        (selectionChange)="filterGoItems($event)"
                      >
                        <mat-option
                          *ngFor="let data of faciUsers"
                          [value]="data.id"
                          >{{ data.firstName + " " + data.lastName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="user-selection">
                    <mat-form-field class="sm-w-100">
                      <mat-label>CIT</mat-label>
                      <mat-select
                        formControlName="citUser"
                        (selectionChange)="filterGoItems($event)"
                      >
                        <mat-option
                          *ngFor="let data of citUsers"
                          [value]="data.id"
                          >{{ data.firstName + " " + data.lastName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="user-selection">
                    <mat-form-field class="sm-w-100">
                      <mat-label>Consultant</mat-label>
                      <mat-select
                        formControlName="conUser"
                        (selectionChange)="filterGoItems($event)"
                      >
                        <mat-option
                          *ngFor="let data of conUsers"
                          [value]="data.id"
                          >{{ data.firstName + " " + data.lastName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="user-selection">
                    <mat-form-field class="sm-w-100">
                      <mat-label>Technician</mat-label>
                      <mat-select
                        formControlName="techUser"
                        (selectionChange)="filterGoItems($event)"
                      >
                        <mat-option
                          *ngFor="let data of techUsers"
                          [value]="data.id"
                          >{{ data.firstName + " " + data.lastName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="user-selection">
                    <mat-form-field class="sm-w-100">
                      <mat-label>Coordinator</mat-label>
                      <mat-select
                        formControlName="corUser"
                        (selectionChange)="filterGoItems($event)"
                      >
                        <mat-option
                          *ngFor="let data of coordUsers"
                          [value]="data.id"
                          >{{ data.firstName + " " + data.lastName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="user-selection">
                    <mat-form-field class="sm-w-100">
                      <mat-label>Translate Manager</mat-label>
                      <mat-select
                        formControlName="tranUser"
                        (selectionChange)="filterGoItems($event)"
                      >
                        <mat-option
                          *ngFor="let data of tranUsers"
                          [value]="data.id"
                          >{{ data.firstName + " " + data.lastName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="chartSize">
                    <div class="chartName">Chart</div>
                    <mat-icon 
                    (click)="showProgressModel()"
                    class="iconSize"
                    >donut_small</mat-icon>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>

          <!-- New Project Wise -->
          <div class="flex fontStyle w-100 my-4">
            <div class="orient"><span>Output</span></div>
            <mat-card class="card">
              <table
                mat-table
                [dataSource]="projectAllData"
                class="mat-elevation-z8 select"
              >
        
              <ng-container matColumnDef="projectName">
                <th mat-header-cell *matHeaderCellDef>Project Name</th>
                <td mat-cell class="capitalize" *matCellDef="let element">
                  {{ element.projectName }}
                </td>
              </ng-container>

                <ng-container matColumnDef="projectLanguage">
                  <th mat-header-cell *matHeaderCellDef>Target Language</th>
                  <td mat-cell class="capitalize" *matCellDef="let element">
                    {{ element.projectLanguage }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="upload">
                  <th mat-header-cell *matHeaderCellDef>Uploaded</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.upload }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="draft">
                  <th mat-header-cell *matHeaderCellDef>Draft</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.draft }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="teamCheck">
                  <th mat-header-cell *matHeaderCellDef>Team Review</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.teamCheck }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="communityCheck">
                  <th mat-header-cell *matHeaderCellDef>Community Review</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.communityCheck }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="backTranslation">
                  <th mat-header-cell *matHeaderCellDef>Back Translation</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.backTranslation }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="consultantCheck">
                  <th mat-header-cell *matHeaderCellDef>Consultant Review</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.consultantCheck }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="citCheck">
                  <th mat-header-cell *matHeaderCellDef>CIT Review</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.citCheck }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="masterRecord">
                  <th mat-header-cell *matHeaderCellDef>Master Record</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.masterRecord }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="publishing">
                  <th mat-header-cell *matHeaderCellDef>Publishing</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.publishing }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="projectAllColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: projectAllColumns"
                ></tr>
              </table>
              <div [hidden]="projectAllData && projectAllData.length <=5">
                <mat-paginator
                #changePageProject="matPaginator"  
                (page)="changePageProjects($event)" 
                class="mat-paginator-outer-container"
                [pageSizeOptions]="[5, 10, 20, 30, 40, 50]" 
                [pageSize]="5"
                showFirstLastButtons></mat-paginator>
            </div>
            </mat-card>
          </div>

          <!-- Book Wise -->
          <div class="fontStyle w-100 my-4">
            <mat-card class="">
              <table
                mat-table
                [dataSource]="projectBooksSource"
                class="mat-elevation-z8 select"
              >
        
                <ng-container matColumnDef="projectLanguage">
                  <th mat-header-cell *matHeaderCellDef>Target Language</th>
                  <td mat-cell class="capitalize" *matCellDef="let element">
                    {{ element.projectLanguage }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="bookName">
                  <th mat-header-cell *matHeaderCellDef>Book Name</th>
                  <td mat-cell class="capitalize" *matCellDef="let element">
                    {{ element.bookName }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="upload">
                  <th mat-header-cell *matHeaderCellDef>Uploaded</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.upload }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="draft">
                  <th mat-header-cell *matHeaderCellDef>Draft</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.draft }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="teamCheck">
                  <th mat-header-cell *matHeaderCellDef>Team Review</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.teamCheck }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="communityCheck">
                  <th mat-header-cell *matHeaderCellDef>Community Review</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.communityCheck }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="backTranslation">
                  <th mat-header-cell *matHeaderCellDef>Back Translation</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.backTranslation }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="consultantCheck">
                  <th mat-header-cell *matHeaderCellDef>Consultant Review</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.consultantCheck }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="citCheck">
                  <th mat-header-cell *matHeaderCellDef>CIT Review</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.citCheck }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="masterRecord">
                  <th mat-header-cell *matHeaderCellDef>Master Record</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.masterRecord }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="publishing">
                  <th mat-header-cell *matHeaderCellDef>Publishing</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.publishing }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="projectBooksColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: projectBooksColumns"
                ></tr>
              </table>
              <div [hidden]="projectBooksSource && projectBooksSource.length <=5">
                <mat-paginator
                #changePageBook="matPaginator"  
                (page)="changePageBooks($event)" 
                class="mat-paginator-outer-container"
                [pageSizeOptions]="[5, 10, 20, 30, 40, 50]" 
                [pageSize]="5"
                showFirstLastButtons></mat-paginator>
            </div>
            </mat-card>
          </div>

          <!-- User Wise -->
          <div class="flex fontStyle w-100 my-4">
            <mat-card class="card">
              <table
                mat-table
                [dataSource]="projectUsersData"
                class="mat-elevation-z8 select"
              >
        
                <ng-container matColumnDef="projectLanguage">
                  <th mat-header-cell *matHeaderCellDef>Target Language</th>
                  <td mat-cell class="capitalize" *matCellDef="let element">
                    {{ element.projectLanguage }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="userName">
                  <th mat-header-cell *matHeaderCellDef>User Name</th>
                  <td mat-cell class="capitalize" *matCellDef="let element">
                    {{ element.userName }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="upload">
                  <th mat-header-cell *matHeaderCellDef>Uploaded</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.upload }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="draft">
                  <th mat-header-cell *matHeaderCellDef>Draft</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.draft }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="teamCheck">
                  <th mat-header-cell *matHeaderCellDef>Team Review</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.teamCheck }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="communityCheck">
                  <th mat-header-cell *matHeaderCellDef>Community Review</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.communityCheck }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="backTranslation">
                  <th mat-header-cell *matHeaderCellDef>Back Translation</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.backTranslation }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="consultantCheck">
                  <th mat-header-cell *matHeaderCellDef>Consultant Review</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.consultantCheck }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="citCheck">
                  <th mat-header-cell *matHeaderCellDef>CIT Review</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.citCheck }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="masterRecord">
                  <th mat-header-cell *matHeaderCellDef>Master Record</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.masterRecord }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="publishing">
                  <th mat-header-cell *matHeaderCellDef>Publishing</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.publishing }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="projectUsersColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: projectUsersColumns"
                ></tr>
              </table>
              <div [hidden]="projectUsersData && projectUsersData.length <=5">
                <mat-paginator
                #changePageUser="matPaginator" 
                (page)="changePageUsers($event)" 
                class="mat-paginator-outer-container"
                [pageSizeOptions]="[5, 10, 20, 30, 40, 50]" 
                [pageSize]="5"
                showFirstLastButtons></mat-paginator>
              </div>
            </mat-card>
          </div>

          <!-- <div class="flex fontStyle w-100 my-4">
            <mat-card class="card">
              <table
                mat-table
                [dataSource]="dataSource3"
                class="mat-elevation-z8 select"
              >
                <ng-container matColumnDef="PendingMTT">
                  <th mat-header-cell *matHeaderCellDef>Pending-MTT</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.pendingMTT }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="PendingEA">
                  <th mat-header-cell *matHeaderCellDef>Pending-EA</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.pendingEA }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="PendingCIT">
                  <th mat-header-cell *matHeaderCellDef>Pending-CIT</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.pendingCIT }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="PendingCons">
                  <th mat-header-cell *matHeaderCellDef>Pending-Cons</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.pendingCons }}
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns3"
                ></tr>
              </table>
            </mat-card>
          </div> -->

          <!-- <div class="flex fontStyle">
            <div class="orient"><span>Snippet</span></div>
            <mat-card class="snippet">
              <div class="grid grid-cols-7 mx-2 pt-2 sm-grid-cols-1">
                <div class="py-2"><span class="fontStyle">Name</span></div>
                <div class="py-2"><span class="fontStyle">Language</span></div>
                <div class="py-2"><span class="fontStyle">Chapter</span></div>
                <div class="py-2"><span class="fontStyle">Book</span></div>
                <div class="py-2"><span class="fontStyle">Chunk</span></div>
                <div class="py-2">
                  <span class="fontStyle">First Accpeted Date</span>
                </div>
                <div class="py-2">
                  <span class="fontStyle">Pending Since</span>
                </div>
              </div>
            </mat-card>
          </div> -->
        </div>
      </mat-content>
    </mat-card>
  </ng-container>
</form>
