<ng-container *ngIf="isDisplay">
    <mat-card>
        <div class="main-project-header">
            <h2 class="text-primary dashboard">Dashboard</h2>
        </div>
        <div [hidden]="!(projectList && projectList.length)">
            <div class="search background-primary searchbar">
                <mat-label>
                    Search
                </mat-label>
                <input type="search" placeholder="Search Projects" class="searchBar" [(ngModel)]="search"
                    (ngModelChange)="filterProjects(search)">
            </div>
            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8"
                *ngIf="projectList && projectList.length ">
                <ng-container matColumnDef="position">
                    <th mat-header-cell *matHeaderCellDef class="header-format"> S.No</th>
                    <td mat-cell *matCellDef="let element; let i = index;"> {{itemSerialNumber+i}}</td>
                </ng-container>
                <ng-container matColumnDef="projectName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-format">Project Name</th>
                    <td mat-cell *matCellDef="let element" (click)="getBooksPercentage(element,element.language, 'translated')"
                        class="data-format project-name"> {{element.projectName}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="projectType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-format">Project Type</th>
                    <td mat-cell *matCellDef="let element" class="data-format capitalize">{{element.projectType}}</td>
                </ng-container>

        <ng-container matColumnDef="language">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="header-format"
          >
            Target Language
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="data-format capitalize"
            style="padding-left: 40px"
          >
            {{ element.language }}
          </td>
        </ng-container>
        <ng-container
          matColumnDef="settings"
          *ngIf="adminLoggedIn && currentRole !== 'Coordinator'"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="header-format"
          >
            Action
          </th>
          <td mat-cell *matCellDef="let element">
            <button
              class="edit-delete cursor-pointer background-primary btn-primary"
              [routerLink]="['/edit-project', element._id]"
            >
              Edit
            </button>
          </td>
        </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div [hidden]="projectList && projectList.length <=5">
                <mat-paginator 
                (page)="changePage($event)" 
                class="mat-paginator-outer-container"
                [pageSizeOptions]="[5,10,20,30,40,50]" 
                [pageSize]="20"
                showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </mat-card>
    <mat-card class="no-projects" *ngIf="projectList && projectList.length < 1">
        <h3>Projects Not Found!</h3>
    </mat-card>

</ng-container>
