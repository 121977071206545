<mat-toolbar color="secondary" class="scroll-bar">
  <mat-toolbar-row>
    <button
      type="button"
      aria-label="Toggle sidenav"
      mat-icon-button
      color="secondary"
      class="navicon"
      (click)="toggleNavbar()"
    >
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
    <span color="primary" class="rhema-logo" (click)="redirectToHome()">
      <img src="../assets/images/logo.png" height="100" class="logo-img" />
    </span>
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container autosize class="side-nav-container">
  <mat-sidenav
    #sidenav
    mode="side"
    opened="isExpanded"
    role="navigation"
    [ngClass]="isExpanded == true ? 'sidenav-display' : ' '"
    [ngStyle]="{ width: isExpanded ? '10%' : '25%' }"
  >
    <mat-nav-list>
      <ng-container *ngIf="role == 'SuperAdmin'">
        <mat-list-item [routerLink]="'/analytics'" class="mb-8 text-center">
          <div>
            <div>
              <mat-icon>
                <span
                  class="material-symbols-outlined"
                  mat-raised-button
                  matTooltipPosition="right"
                  matTooltip="Dashboard"
                  routerLinkActive="is-active"
                >
                  query_stats
                </span>
              </mat-icon>
            </div>
            <div>
              <span *ngIf="isExpanded" class="menu-item text-lg"
                >Dashboard</span
              >
            </div>
          </div>
        </mat-list-item>
        <mat-list-item [routerLink]="'/create-user'" class="mb-8 text-center">
          <div>
            <div>
              <mat-icon>
                <span
                  class="material-symbols-outlined icon-color"
                  mat-raised-button
                  matTooltipPosition="right"
                  matTooltip="Create Account"
                  routerLinkActive="is-active"
                >
                  person_add
                </span>
              </mat-icon>
            </div>
            <div>
              <span *ngIf="isExpanded" class="menu-item text-lg"
                >Create Account</span
              >
            </div>
          </div>
        </mat-list-item>
        <mat-list-item
          [routerLink]="'/create-language'"
          class="mb-8 text-center"
        >
          <!-- <img class="img-sidebar" src="/assets/images/AddLang2.png" alt="" /> -->
          <div>
            <mat-icon>
              <span
                class="material-symbols-outlined icon-color"
                mat-raised-button
                matTooltipPosition="right"
                matTooltip="Create Language"
                routerLinkActive="is-active"
              >
                translate
              </span>
            </mat-icon>
            <div>
              <span *ngIf="isExpanded" class="menu-item text-lg"
                >Create Language</span
              >
            </div>
          </div>
        </mat-list-item>
        <mat-list-item
          [routerLink]="'/create-project'"
          class="mb-8 text-center"
        >
          <!-- <img class="img-sidebar" src="/assets/images/createproject1.jpg" alt="" /> -->
          <div>
            <mat-icon>
              <span
                class="material-symbols-outlined icon-color"
                mat-raised-button
                matTooltipPosition="right"
                matTooltip="Create project"
                routerLinkActive="is-active"
              >
                add_task
              </span>
            </mat-icon>
            <div>
              <span *ngIf="isExpanded" class="menu-item text-lg nav-text"
                >Create Project</span
              >
            </div>
          </div>
        </mat-list-item>
        <mat-list-item [routerLink]="'/dashboard'" class="mb-8 text-center">
          <!-- <img class="img-sidebar" src="/assets/images/project.png" alt="" /> -->
          <div>
            <mat-icon>
              <span
                class="material-symbols-outlined icon-color"
                mat-raised-button
                matTooltipPosition="right"
                matTooltip="Projects"
                routerLinkActive="is-active"
              >
                task_alt
              </span>
            </mat-icon>
            <div>
              <span *ngIf="isExpanded" class="menu-item text-lg nav-text"
                >Projects</span
              >
            </div>
          </div>
        </mat-list-item>
        <mat-list-item [routerLink]="'/user-details'" class="mb-8 text-center">
          <!-- <img class="img-sidebar" src="/assets/images/group.png" alt="" /> -->
          <div>
            <mat-icon
              ><span
                class="material-symbols-outlined icon-color"
                mat-raised-button
                matTooltipPosition="right"
                matTooltip="Users"
                routerLinkActive="is-active"
              >
                diversity_3
              </span></mat-icon
            >
            <div>
              <span *ngIf="isExpanded" class="menu-item text-lg nav-text"
                >Users</span
              >
            </div>
          </div>
        </mat-list-item>
        <mat-list-item
          [routerLink]="'/testment-books'"
          class="mb-2 text-center"
        >
          <!-- <img class="img-sidebar" src="/assets/images/addfile.png" alt="" /> -->
          <div>
            <mat-icon>
              <span
                class="material-symbols-outlined icon-color"
                mat-raised-button
                matTooltipPosition="right"
                matTooltip="Source files"
                routerLinkActive="is-active"
              >
                folder_open
              </span>
            </mat-icon>
            <div>
              <span *ngIf="isExpanded" class="menu-item text-lg nav-text"
                >Source Files</span
              >
            </div>
          </div>
        </mat-list-item>
        <mat-list-item
          *ngIf="email == 'app@marisree.tech'"
          [routerLink]="'/delete-testment-books'"
          class="mb-2 text-center"
        >
          <!-- <img class="img-sidebar" src="/assets/images/addfile.png" alt="" /> -->
          <div>
            <mat-icon>
              <span
                class="material-symbols-outlined icon-color"
                mat-raised-button
                matTooltipPosition="right"
                matTooltip="Source files"
                routerLinkActive="is-active"
              >
                delete_outline
              </span>
            </mat-icon>
            <div>
              <span *ngIf="isExpanded" class="menu-item text-lg nav-text"
                >Delete Files</span
              >
            </div>
          </div>
        </mat-list-item>
        <ng-container>
          <mat-list-item>
            <div (click)="showDropdownOption('uploadFilesExpanded')">
              <mat-icon>
                <span
                  class="material-symbols-outlined icon-color"
                  mat-raised-button
                  matTooltipPosition="right"
                  matTooltip="Uplaod files"
                >
                  cloud_upload
                </span>
              </mat-icon>
              <mat-icon>
                <span class="material-symbols-outlined"> expand_more </span>
              </mat-icon>
              <div>
                <span *ngIf="isExpanded" class="menu-item text-lg nav-text"
                  >Upload Files</span
                >
              </div>
            </div>
          </mat-list-item>
          <ng-container *ngIf="uploadFilesExpanded">
            <mat-list-item [routerLink]="'/upload-files'">
              <!-- <img class="img-sidebar subImg" src="/assets/images/logout.png" alt="" /> -->
              <div>
                <mat-icon>
                  <span
                    class="material-symbols-outlined icon-color"
                    mat-raised-button
                    matTooltipPosition="right"
                    matTooltip="Audio"
                    routerLinkActive="is-active"
                  >
                    audio_file
                  </span>
                </mat-icon>
                <div>
                  <span *ngIf="isExpanded" class="menu-item text-lg nav-text"
                    >Audio</span
                  >
                </div>
              </div>
            </mat-list-item>
            <mat-list-item
              [routerLink]="'/upload-video-files'"
              class="flex justify-center items-center"
            >
              <!-- <img class="img-sidebar subImg" src="/assets/images/logout.png" alt="" /> -->
              <div class="flex justify-center items-center">
                <div>
                  <mat-icon>
                    <span
                      class="material-symbols-outlined icon-color"
                      mat-raised-button
                      matTooltip="Video File"
                      mat-raised-button
                      matTooltipPosition="right"
                      matTooltip="Video"
                      routerLinkActive="is-active"
                    >
                      video_file
                    </span>
                  </mat-icon>
                  <div>
                    <span *ngIf="isExpanded" class="menu-item text-lg nav-text"
                      >Video</span
                    >
                  </div>
                </div>
              </div>
            </mat-list-item>

            <mat-list-item
              *ngIf="!isSign"
              [routerLink]="'/upload-sign-files'"
              class="flex justify-center items-center"
            >
              <!-- <img class="img-sidebar subImg" src="/assets/images/logout.png" alt="" /> -->
              <div class="flex justify-center items-center">
                <div>
                  <mat-icon>
                    <span
                      class="material-symbols-outlined icon-color"
                      mat-raised-button
                      matTooltip="Video File"
                      mat-raised-button
                      matTooltipPosition="right"
                      matTooltip="Video"
                      routerLinkActive="is-active"
                    >
                      video_file
                    </span>
                  </mat-icon>
                  <div>
                    <span *ngIf="isExpanded" class="menu-item text-lg nav-text"
                      >Sign</span
                    >
                  </div>
                </div>
              </div>
            </mat-list-item>
          </ng-container>
        </ng-container>
        <ng-container>
          <mat-list-item>
            <div class="flex justify-center items-center py-4">
              <div (click)="showDropdownOption('chatbotNavExpanded')">
                <mat-icon>
                  <span
                    class="material-symbols-outlined icon-color"
                    mat-raised-button
                    matTooltipPosition="right"
                    matTooltip="Chatbot Resource"
                  >
                    smart_toy
                  </span>
                </mat-icon>
                <mat-icon>
                  <span class="material-symbols-outlined"> expand_more </span>
                </mat-icon>
                <div>
                  <span *ngIf="isExpanded" class="menu-item text-lg nav-text"
                    >Chatbot Resource</span
                  >
                </div>
              </div>
            </div>
          </mat-list-item>
          <ng-container *ngIf="chatbotNavExpanded">
            <mat-list-item [routerLink]="'/upload-chatbot-mtt-files'">
              <div>
                <mat-icon
                  ><span
                    class="material-symbols-outlined icon-color"
                    mat-raised-button
                    matTooltipPosition="right"
                    matTooltip="MTT"
                  >
                    cloud_sync
                  </span></mat-icon
                >
                <div>
                  <span *ngIf="isExpanded" class="menu-item text-lg nav-text"
                    >MTT</span
                  >
                </div>
              </div>
            </mat-list-item>
            <mat-list-item [routerLink]="'/upload-chatbot-cit-files'">
              <div>
                <mat-icon
                  ><span
                    class="material-symbols-outlined icon-color"
                    mat-raised-button
                    matTooltipPosition="right"
                    matTooltip="CIT"
                  >
                    sign_language
                  </span></mat-icon
                >
                <div>
                  <span *ngIf="isExpanded" class="menu-item text-lg nav-text"
                    >CIT</span
                  >
                </div>
              </div>
            </mat-list-item>
          </ng-container>
        </ng-container>
        <mat-list-item
          [routerLink]="'/reset-password'"
          class="mb-2 text-center"
        >
          <!-- <img class="img-sidebar" src="/assets/images/password.png" alt="" /> -->
          <div>
            <mat-icon>
              <span
                class="icon-color"
                mat-raised-button
                matTooltipPosition="right"
                matTooltip="Reset password"
                routerLinkActive="is-active"
              >
                manage_accounts
              </span>
            </mat-icon>
            <div>
              <span *ngIf="isExpanded" class="menu-item text-lg nav-text"
                >Reset Password</span
              >
            </div>
          </div>
        </mat-list-item>
      </ng-container>
      <ng-container *ngIf="role == 'Translation Manager'">
        <mat-list-item (click)="trans()" class="mb-8 text-center">
          <div>
            <mat-icon>assignment_ind</mat-icon>
            <div>
              <span *ngIf="isExpanded" class="menu-item text-lg nav-text"
                >TimeLine Request</span
              >
            </div>
          </div>
        </mat-list-item>
        <mat-list-item
          [routerLink]="linkEnable == false ? '/performance' : null"
          class="mb-8 text-center"
        >
          <!-- <img class="img-sidebar" src="/assets/images/group.png" alt="" /> -->
          <div>
            <mat-icon>
              <span
                class="material-symbols-outlined icon-color"
                mat-raised-button
                matTooltipPosition="right"
                matTooltip="Performance"
                routerLinkActive="is-active"
              >
                insights
              </span>
            </mat-icon>
            <div>
              <span *ngIf="isExpanded" class="menu-item text-lg nav-text"
                >Performance</span
              >
            </div>
          </div>
        </mat-list-item>
      </ng-container>

      <ng-container *ngIf="role != 'Translation Manager'">
        <mat-list-item [routerLink]="'/support'" class="mb-8 text-center">
          <!-- <img class="img-sidebar" src="/assets/images/project.png" alt="" /> -->
          <div>
            <mat-icon>
              <span
                class="material-symbols-outlined icon-color"
                mat-raised-button
                matTooltipPosition="right"
                matTooltip="Support"
                routerLinkActive="is-active"
              >
                support_agent
              </span>
            </mat-icon>
            <div>
              <span *ngIf="isExpanded" class="menu-item text-lg nav-text"
                >Support</span
              >
            </div>
          </div>
        </mat-list-item>
      </ng-container>

      <mat-list-item
        [routerLink]="'/testment-books'"
        (click)="logOut()"
        class="mb-8 text-center"
      >
        <!-- <img class="img-sidebar" src="/assets/images/logout.png" alt="" /> -->
        <div>
          <mat-icon
            ><span
              class="material-symbols-outlined icon-color"
              mat-raised-button
              matTooltipPosition="right"
              matTooltip="Logout"
            >
              logout
            </span></mat-icon
          >
          <div>
            <span *ngIf="isExpanded" class="menu-item text-lg nav-text"
              >Log Out</span
            >
          </div>
        </div>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
