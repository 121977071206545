import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProjectProfileService } from 'src/app/services/project-profile/project-profile.service';
import { UserDataService } from 'src/app/services/user-data/user-data.service';

@Component({
  selector: 'app-country-coordinator',
  templateUrl: './country-coordinator.component.html',
  styleUrls: ['./country-coordinator.component.scss'],
})
export class CountryCoordinatorComponent implements OnInit {
  @Input('countryCordinatorsGroup')
  public countryCordinatorsGroup: FormGroup | any;
  countryCordinators: any;
  email: string | any;
  phNo: string | any;
  country: any;
  @Input() projectId: any | '';
  @Input() setProjectRole: any;

  @Input() set countryName(value: string) {
    this._countryName = value;
    if (this._countryName) {
      this.getUserByRole('Country Coordinator');
    }
  }
  
  get countryName(): string {
    return this._countryName;
  }
  
  private _countryName: string = '';

  timeLineInfo: any;

  constructor(
    private userDataService: UserDataService,
    private projectProfileService: ProjectProfileService
  ) {}

  ngOnInit(): void {
    this.getUserByRole('Country Coordinator');
  }

  getUserByRole(role: string) {
    console.log('countryName', this._countryName);

    this.userDataService.getUserByRole(role).subscribe(async (userData) => {
      userData = userData.filter((user: any) => user.country === this._countryName);
      this.countryCordinators = this.alphaOrder(userData, 'firstName');
      this.email = '';
      this.phNo = '';

      if (this.setProjectRole) {
        const index = this.countryCordinators.findIndex(
          (data: any) => data._id === this.setProjectRole._id
        );

        this.countryCordinatorsGroup.controls.countryCoordinator.setValue(
          this.countryCordinators[index]
        );

        this.setValue(this.countryCordinators[index]);

        this.countryCordinatorsGroup.controls.countryCoordinator.disable();
      }
    });
  }

  setValue(object: any) {
    try {
      this.email = object.email;
      this.phNo = object.mobileNumber;
    } catch (error) {
      console.log(error);
    }
  }

  alphaOrder(list: any, fieldName: any) {
    return list.sort((a: any, b: any) => {
      if (a[fieldName] > b[fieldName]) return 1;
      else if (a[fieldName] < b[fieldName]) return -1;
      else return 0;
    });
  }

  showUser() {
    this.projectProfileService.showTimeline(
      this.timeLineInfo.id,
      this.projectId,
      this.timeLineInfo.role
    );
  }
}
