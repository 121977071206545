import { Component, OnInit, Inject } from '@angular/core';
import { SupportComponent } from '../support/support.component';
import { SupportService } from 'src/app/services/support/support.service';
import { environment } from '../../../environments/environment';
import { DialogsService } from 'src/app/services/dialog/dialog.service';
import { PopUpModalComponent } from 'src/app/components/pop-up-modal/pop-up-modal.component';

import { type } from 'os';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageViewComponent } from '../image-view/image-view.component';
// import {Utils} from 'tslint'

@Component({
  selector: 'app-support-attach-view',
  templateUrl: './support-attach-view.component.html',
  styleUrls: ['./support-attach-view.component.scss'],
})
export class SupportAttachViewComponent implements OnInit {
  fileData: any = Blob;
  image: any = [];
  video: any = [];
  bugData: any = [];
  bugFiles: any = [];
  base64: any;
  imagePath: any = String;
  res: any;
  isDisplay: any = false;
  defaultSelect:any;
  supportStatus: any=['To Do','In Progress','Resolve'];
  statusValue: any;
  statusChange: any;
  isCheck: boolean=false;
  
  constructor(
    public supportService: SupportService,
    private dialogService: DialogsService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<SupportAttachViewComponent>,
    @Inject(MAT_DIALOG_DATA) data: SupportComponent
  ) {
    this.bugData = data;
    this.statusValue= this.bugData.status;
  }

  async ngOnInit() {    
    this.loadFiles();
  }

  async SelectSupportProgress(event: any){
    this.statusValue=event;
  }

  async bugSubmit(){    
    this.supportService.updateSupportStatus(this.bugData.id,this.statusValue).subscribe(async(res)=>{
      this.statusChange={...res,status:this.statusValue,isCheck:true};
      this.dialog.open(PopUpModalComponent, {
        data: {
          message: res.length!=0 ? 'The status has been changed successfully' : 'No status changed'
        },
      });
      this.isCheck=true;
    })
  }


  async closeModal() {
    if(this.isCheck==true){
      this.dialogRef.close(this.statusChange);
    }else{
      this.dialogRef.close({...this.bugData,isCheck:false});
    }
  }

  async loadFiles() {    
    await this.bugData.data.map((file: any) => {
      file.fileUrl = `${environment.supportURL}${file.fileId}/${file.fileName}`;
    });
  }
  imageView(index: any){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
    data:this.bugData.data[index]
    };
    dialogConfig.height="70%";
    dialogConfig.width="100%"
    const dialogRef = this.dialog.open(ImageViewComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.dialogService.closeModal();
    });
  }
}
