import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { PieChartComponent } from 'src/app/components/pie-chart/pie-chart.component';
import { PopUpModalComponent } from 'src/app/components/pop-up-modal/pop-up-modal.component';
import { BibleBooksService } from 'src/app/services/bible-book/bible-book.service';
import { CountryService } from 'src/app/services/country/country.service';
import { DialogsService } from 'src/app/services/dialog/dialog.service';
import { ProjectProfileService } from 'src/app/services/project-profile/project-profile.service';
import { UserDataService } from 'src/app/services/user-data/user-data.service';
@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.scss'],
})
export class PerformanceComponent implements OnInit {
  performanceProfile: FormGroup | any;
  country: any;
  languageSelect: string | undefined;
  chapterName: any = [];
  filter: any={};

  selectValue: any;
  countryNameList: any = [];
  countryOptionList: any;
  // countryName: any;
  itemSerialNumber_0: any;
  itemSerialNumber_1: any;
  itemSerialNumber_2: any;
  isDisplay: boolean = false;
  isBook: boolean = true;
  isChapter: boolean = true;
  checkBook: any = [];
  checkChapter: any = [];
  chapters: any = [];
  chaptersList: any = [];
  projectName: any = [];
  bookName: any = [];
  languageName: any = [];
  dateError: boolean | any;
  mttUsers: any = [];
  faciUsers: any = [];
  projectCheck: any = [];
  citUsers: any = [];
  conUsers: any = [];
  techUsers: any = [];
  coordUsers: any = [];
  tranUsers: any = [];
  userInfo: any = [];
  userFilter: any = [];
  countryName: any;
  uploading: any = [];
  completed: any = [];
  pending: any = [];
  isOutput: boolean = false;
  projectDetail: any;

  projectAllColumns: string[] = [
    'projectName',
    'projectLanguage',
    'upload',
    'draft',
    'teamCheck',
    'communityCheck',
    'backTranslation',
    'consultantCheck',
    'citCheck',
    'masterRecord',
    'publishing',
  ];
  projectBooksColumns: string[] = [
    'projectLanguage',
    'bookName',
    'upload',
    'draft',
    'teamCheck',
    'communityCheck',
    'backTranslation',
    'consultantCheck',
    'citCheck',
    'masterRecord',
    'publishing',
  ];
  projectUsersColumns: string[] = [
    'projectLanguage',
    'userName',
    'upload',
    'draft',
    'teamCheck',
    'communityCheck',
    'backTranslation',
    'consultantCheck',
    'citCheck',
    'masterRecord',
    'publishing',
  ];
  //displayedColumns3: string[]=['PendingMTT','PendingEA','PendingCIT','PendingCons'];
  projectAllData: any = [];
  projectBooksSource: any = [];
  projectUsersData: any = [];
  dataSource3: any = [];
  isExists: any;
  startDate: any;
  endDate: any;
  @ViewChild('changePageProject') paginator_0: MatPaginator | any;
  @ViewChild('changePageBook') paginator_1: MatPaginator | any;
  @ViewChild('changePageUser') paginator_2: MatPaginator | any;

  
  chartData: any;

  performanceFormControls() {
    this.performanceProfile = new FormGroup({
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
      countryName: new FormControl('', Validators.required),
      projectName: new FormControl('', Validators.required),
      language: new FormControl('', Validators.required),
      book: new FormControl('', Validators.required),
      chapter: new FormControl('', Validators.required),
      mttUser: new FormControl('', Validators.required),
      faciUser: new FormControl('', Validators.required),
      citUser: new FormControl('', Validators.required),
      conUser: new FormControl('', Validators.required),
      corUser: new FormControl('', Validators.required),
      techUser: new FormControl('', Validators.required),
      tranUser: new FormControl('', Validators.required),
    });
  }

  constructor(
    private httpClient: HttpClient,
    private dialogService: DialogsService,
    private countryService: CountryService,
    private projectProfileService: ProjectProfileService,
    private bibleBooksService: BibleBooksService,
    private userDataService: UserDataService,
    private dialog: MatDialog
  ) {
    this.bookName = [];
  }

  clearVariables() {
    this.bookName = [];
    this.chaptersList = [];
    this.languageName = [];
    this.projectName = [];
    this.mttUsers = [];
    this.faciUsers = [];
    this.techUsers=[];
    this.citUsers = [];
    this.conUsers = [];
    this.coordUsers = [];
    this.tranUsers = [];
  }
  async ngOnInit() {
    this.performanceFormControls();
    await this.getCountryFromProject();
    //NEED to check, NOT working
    // await this.loadCountryDetail(this.countryNameList[0]);
    // this.countryName = this.countryNameList[0];
    // this.filter[0]={countryName: this.countryNameList[0]}

    // await this.loadCountryDetail('India');
    this.countryName = 'india';
    // this.filter[0] = { countryName: 'india' };
    this.filter['countryName']='india'
  }

  // const toPascalCase = (str: any) => (str.match(/[a-zA-Z0-9]+/g) || []).map((w:any) => `${w.charAt(0).toUpperCase()}${w.slice(1)}`).join('');
  toPascalCase(str: any) {
    return str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x: any) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
      .join('');
  }

  async getCountryFromProject() {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const data = {};
        let result;
        this.bibleBooksService.getCountriesByProject(data).subscribe(
          async (response: any) => {
            for(let i=0;i<response.length;i++){
              result = await this.toPascalCase(response[i]);          
              this.countryNameList.push(result);
            }
            await this.loadCountryDetail('India');
            // await response.forEach(async (project: any) => {
            //   console.log("project+++",project);
              
            //   result = await this.toPascalCase(project);          
            //   this.countryNameList.push(result);
            // });
          },
          (err: any) => {},
          () => {}
        );
        resolve(this.countryNameList);  
      } catch (error) {
        console.log("error",error);
      }
    });
  }

  async loadCountryDetail(value: string) {
    return new Promise(async (resolve, reject) => {
      try {
        this.countryName = value.toLowerCase();
        
        this.clearVariables();
        // this.httpClient
        //   .get('https://restcountries.com/v3.1/name/' + value)
        //   .subscribe(
        //     async (res: any) => {
        //       console.log(res);
        //       this.country = await res.filter(
        //         (re: any) => re.name.common === 'India'
        //       )[0];
    
        //       this.countryOptionList = this.countryNameList.filter(
        //         (name) => name != value
        //       );
        //       this.countryName = new FormControl(this.country.name.common);
        //       this.dialogService.closeModal();
        //       this.isDisplay = true;
        //     },
        //     (err) => {
        //       console.log('error ', err);
        //     }
        //   );
        // console.log('Coutnry Input Value ', value);
        // this.projectFind(value);
        this.countryService.getCountryByName(value).subscribe(
          async (res: any) => {
            this.country = await res.filter(
              (re: any) => re.name.common === value
            )[0];   
            this.countryOptionList = await this.countryNameList
              .sort()
              .filter((name: any) => name != value);    
            this.performanceProfile.controls['countryName'].setValue(
              this.country.name.common
            );
            //this.dialogService.closeModal();
            this.isDisplay = true;
          },
          (err: any) => {},
          () => {}
        );
        await this.populateUsers(this.countryName);
      } catch (error) {
        console.log("error",error);
      }
    });
  }

  filterGoItems(event: any) {    
    //IN PROGRESS
    const userName = event.source.ngControl.name;
    let userValue = event.value;    
    if (userName == 'projectName') {
      userValue = event.value.projectName;
    }
      this.filter[userName]=userValue;

    // const index = this.filter.findIndex(
    //   (user: any) => Object.keys(user)[0] == userName
    // );
    // if (index == -1) {
    //   this.filter={...{[userName]:userValue}}
    //   console.log("filter====",this.filter);
    //   this.filter[userName]=userValue;
      // this.filter.push({ [userName]: userValue });
      // Object.assign(this.filter,{[userName]:userValue});
    // } else {
    //   this.filter[userName]=userValue;
      // this.filter[index] = { [userName]: userValue };
      // this.filter[userName]=userValue;
    // }
  }

  async populateUsers(locationName: any) {
    await this.projectProfileService
      .getProjectsByCountry(locationName)
      .subscribe(async (response) => {
        await this.getUserDetails(response).then(data=> this.projectDetail=data)
      });
  }

  async getUserDetails(event: any) {
    this.clearVariables();
    // this.projectName = event;
    event.forEach((project: any) => {
      project.MTT.forEach((mtt: any) => {
        this.userDataService
          .getUserDetailById(mtt._id)
          .subscribe((response) => {
            this.isExists = this.mttUsers.findIndex(
              (mtt: any) => mtt.id === response.id
            );
            if (this.isExists == -1) {
              this.mttUsers.push(response);
            }
          });
      });
      project.technician.forEach((tech: any) => {
        this.userDataService
          .getUserDetailById(tech._id)
          .subscribe((response) => {
            this.isExists = this.techUsers.findIndex(
              (tech: any) => tech.id === response.id
            );
            if (this.isExists == -1) {
              this.techUsers.push(response);
            }
          });
      });
      project.cit.forEach((cit: any) => {
        this.userDataService
          .getUserDetailById(cit._id)
          .subscribe((response) => {
            this.isExists = this.citUsers.findIndex(
              (cit: any) => cit.id === response.id
            );
            if (this.isExists == -1) {
              this.citUsers.push(response);
            }
          });
      });
      project.consultant.forEach((con: any) => {
        this.userDataService
          .getUserDetailById(con._id)
          .subscribe((response) => {
            this.isExists = this.conUsers.findIndex(
              (con: any) => con.id === response.id
            );
            if (this.isExists == -1) {
              this.conUsers.push(response);
            }
          });
      });
      project.facilitator.forEach((faci: any) => {
        this.userDataService
          .getUserDetailById(faci._id)
          .subscribe((response) => {
            this.isExists = this.faciUsers.findIndex(
              (faci: any) => faci.id === response.id
            );
            if (this.isExists == -1) {
              this.faciUsers.push(response);
            }
          });
      });
      project.coordinator.forEach((cor: any) => {
        this.userDataService
          .getUserDetailById(cor._id)
          .subscribe((response) => {
            this.isExists = this.coordUsers.findIndex(
              (cor: any) => cor.id === response.id
            );
            if (this.isExists == -1) {
              this.coordUsers.push(response);
            }
          });
      });
      project.translateManager.forEach((tran: any) => {
        this.userDataService
          .getUserDetailById(tran._id)
          .subscribe((response) => {
            this.isExists = this.tranUsers.findIndex(
              (tran: any) => tran.id === response.id
            );
            if (this.isExists == -1) {
              this.tranUsers.push(response);
            }
          });
      });
    });
    // const index = this.projectNameList.findIndex(
    //   (projectName: any) => projectName.country == event
    // );
    // this.projectName = this.projectNameList[index].projects;
    return event;
  }

  projectLanguages(event: any) {    
    this.getUserDetails([event.value]);
    this.languageName = [];
    this.filterGoItems(event);
    this.performanceProfile.controls.projectName.value =
      event.value.projectName;
    this.languageName.push(event.value.language);
  }

  async getBookName(event: any) {
  let project = await  this.projectDetail.filter((item:any)=>item.language == event.value)
    this.getUserDetails(project);
    this.languageName.push(event.value);
    this.filterGoItems(event);
    // const index = this.languageName.findIndex(
    //   (item: any) => item.langId == event
    // );
    this.languageSelect = event.value;
    const data = {
      language: event.value,
      targetType: 'source',
    };
    this.bibleBooksService.getBooksByPerformanceLanguage(data).subscribe(
      (response) => {
        this.bookName = response;
      },
      (err) => {
        console.log('Server errr ======== ', err);
      }
    );
  }

  getChapters(event: any) {
    this.filterGoItems(event);
    // this.chaptersList=event;
    const chaptersData = {
      bookName: event.value,
      language: this.languageSelect,
      targetType: 'source',
    };

    this.bibleBooksService
      .getChaptersByPerformance(chaptersData)
      .subscribe((response) => {
        this.chaptersList = response;
        // this.getChapters(response);
      });
  }

  async getPerformanceData() {
    this.dialogService.confirm();
    this.bibleBooksService    
      .getPerformance(this.filter)
      .subscribe(async (response) => { 
        this.chartData = response[0][0];
        this.projectAllData = new MatTableDataSource(response[0]);
        this.projectAllData.paginator = this.paginator_0;
        this.projectBooksSource = new MatTableDataSource(response[1]==null ? []:response[1]); //Book Wise
        this.projectBooksSource.paginator = this.paginator_1;
        this.projectUsersData = new MatTableDataSource(response[2]==null ? []:response[2]); //User Wise
        this.projectUsersData.paginator=this.paginator_2;
        //this.dataSource3 = new MatTableDataSource(response[0]);
        this.dialogService.closeModal();
      });
    // this.dialogService.closeModal();
  }

  async dateValidation() {
    this.startDate = await this.performanceProfile.get('startDate').value;
    this.endDate = await this.performanceProfile.get('endDate').value;
  
    if (new Date(this.startDate) > new Date(this.endDate)) {
      this.dateError = true;
    } else {
      this.filter['startDate']=this.startDate;
      this.filter['endDate']=this.endDate;
      this.dateError = false;
    }
  }

  /****************************
   *  Change page
   *****************************/
   changePageProjects(evn: any){
    this.itemSerialNumber_0 = evn.pageSize * evn.pageIndex + 1;
  }

  changePageBooks(evn: any) {    
    this.itemSerialNumber_1 = evn.pageSize * evn.pageIndex + 1;
  }

  changePageUsers(evn: any){
    this.itemSerialNumber_2 = evn.pageSize * evn.pageIndex + 1;
  }

  showProgressModel() {
    const dialogRef = this.dialog.open(PieChartComponent, {
      width: '750px',
      height: 'auto',
      data: {performanceProgress:[
        this.chartData.draft,
        this.chartData.teamCheck,
        this.chartData.communityCheck,
        this.chartData.backTranslation,
        this.chartData.consultantCheck,
        this.chartData.masterRecord,
        this.chartData.publishing,
      ],contentType:'performance'},
      // data: this.data,
    });
  }
}
