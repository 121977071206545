<mat-card class="mat-card sign-up">
  <mat-card-header class="background-primary border-top-5">
    <mat-card-title class="font-primary">Create Account</mat-card-title>
  </mat-card-header>
  <div class="name-div">
    <form [formGroup]="userDetails">
      <mat-card-content>
        <div class="">
          <div class="grid grid-cols-2 sm-grid-cols-1 grill">
            <mat-form-field class="px-4">
              <input matInput placeholder="First Name" formControlName="firstname" required />
              <mat-error *ngIf="
                  userDetails.controls.firstname.errors?.required ||
                  userDetails.controls.firstname.errors?.pattern
                ">Please enter first name
              </mat-error>
            </mat-form-field>
            <mat-form-field class="px-4">
              <input matInput placeholder="Last Name" formControlName="lastname" required />
              <mat-error *ngIf="
                  userDetails.controls.lastname.errors?.required ||
                  userDetails.controls.lastname.errors?.pattern
                ">Please enter last name
              </mat-error>
            </mat-form-field>
          </div>
          <div class="grid grid-cols-2  sm-grid-cols-1  grill">
            <mat-form-field color="accent" class="px-4">
              <input matInput placeholder="Email" formControlName="email" autocomplete="doNotAutoComplete" required />
              <mat-error *ngIf="
                  userDetails.controls.email.errors?.required ||
                  userDetails.controls.email.errors?.pattern
                ">Please enter a valid email
              </mat-error>
            </mat-form-field>
            <mat-form-field color="accent" class="px-4">
              <input type="password" matInput placeholder="Password" formControlName="password"
                autocomplete="doNotAutoComplete" required />
              <mat-error *ngIf="
                  userDetails.controls.password.errors?.required ||
                  userDetails.controls.password.errors?.pattern
                ">
                Please enter a valid password
              </mat-error>
            </mat-form-field>
          </div>
          <div class="grid grid-cols-2  sm-grid-cols-1  grill">
            <mat-form-field class="px-4">
              <mat-label>Select Role</mat-label>
              <mat-select formControlName="role" required ngDefaultControl>
                <mat-option *ngFor="let singleRole of roles" [value]="singleRole.roleName" class="mat-option-custom">
                  {{ singleRole.roleName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="userDetails.controls.role.errors?.required">
                Please select a role
              </mat-error>
            </mat-form-field>
            <mat-form-field class="px-4" *ngIf="
                userDetails.value.role !== 'Consultant' &&
                userDetails.value.role !== 'CIT' &&
                userDetails.value.role !== 'Technician' &&
                userDetails.value.role !== 'Translation Manager' &&
                userDetails.value.role !== 'Coordinator'
              ">
              <mat-label>Select Region</mat-label>
              <mat-select formControlName="region" required ngDefaultControl (selectionChange)="getCountries($event)">
                <mat-option *ngFor="let region of regions" [value]="region" class="mat-option-custom">
                  {{ region.regionName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="userDetails.controls.region.errors?.required">
                Please select a region
              </mat-error>
            </mat-form-field>
          </div>
          <div class="grid grid-cols-2  sm-grid-cols-1  grill">
            <mat-form-field class="px-4" *ngIf="
                userDetails.value.role !== 'Consultant' &&
                userDetails.value.role !== 'CIT' &&
                userDetails.value.role !== 'Technician'&&
                userDetails.value.role !== 'Translation Manager' &&
                userDetails.value.role !== 'Coordinator'
              ">
              <mat-label>Select Country</mat-label>
              <mat-select formControlName="country" required ngDefaultControl (selectionChange)="getLanguages($event)">
                <mat-option *ngFor="let country of countriesList" [value]="country" class="mat-option-custom">
                  {{ country.countryName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="userDetails.controls.country.errors?.required">
                Please select a country
              </mat-error>
            </mat-form-field>

            <mat-form-field class="px-4" *ngIf="
                userDetails.value.role !== 'Consultant' &&
                userDetails.value.role !== 'CIT' &&
                userDetails.value.role !== 'Technician'&&
                userDetails.value.role !== 'Translation Manager' &&
                userDetails.value.role !== 'Coordinator' &&
                userDetails.value.role !== 'Country Coordinator'
              ">
              <mat-label>Select Language</mat-label>
              <mat-select formControlName="language" required ngDefaultControl>
                <mat-option *ngFor="let languageData of languages" [value]="languageData" class="mat-option-custom">
                  {{ languageData.languageName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="userDetails.controls.language.errors?.required">
                Please select a language
              </mat-error>
            </mat-form-field>
          </div>
          <mat-form-field color="accent" class="px-4">
            <input type="number" matInput placeholder="Mobile Number" formControlName="mobileNumber" required />
            <mat-error *ngIf="
                  userDetails.controls.mobileNumber.errors?.required ||
                  userDetails.controls.mobileNumber.errors?.pattern
                ">Please enter a valid Mobile Number
            </mat-error>
          </mat-form-field>
          <div class="button-row">
            <button mat-raised-button [ngClass]="{
                'btn-primary': userDetails.valid,
                'create-btn': true
              }" [disabled]="!userDetails.valid" (click)="createUser()">
              Create User
            </button>
          </div>
        </div>
      </mat-card-content>
    </form>
  </div>
</mat-card>