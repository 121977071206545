<ng-container *ngIf="isDisplay">
  <mat-card class="dash-board">
    <div class="dashboard-header text-white border-top-5">
      <mat-card-header class="bg-primary">
        <mat-card-title>
          <span class="text-primary font-primary text-white"
            >Books Progress</span
          >
        </mat-card-title>
      </mat-card-header>

      <div
        class="dashsboard-table background-primary border-top-5"
        [hidden]="!(books && books.length)"
      >
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          class="mat-elevation-z8"
        >
          <ng-container matColumnDef="Books">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="header-format"
            >
              Books
            </th>
            <td mat-cell *matCellDef="let element" class="data-format">
              {{ element.bookName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Draft">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="header-format"
            >
              Draft
            </th>
            <td mat-cell *matCellDef="let element" class="data-format">
              {{ element.draft }}%
            </td>
          </ng-container>
          <ng-container matColumnDef="TeamCheckProgress">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="header-format"
            >
              Team Check
            </th>
            <td mat-cell *matCellDef="let element" class="data-format items-center">
              {{ element.teamCheck }}%
            </td>
          </ng-container>
          <ng-container matColumnDef="CommunityCheckProgress">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="header-format"
            >
              Community Check
            </th>
            <td mat-cell *matCellDef="let element" class="data-format items-center">
              {{ element.communityCheck }}%
            </td>
          </ng-container>
          <ng-container matColumnDef="Back Translation">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="header-format"
            >
              Back Translation
            </th>
            <td mat-cell *matCellDef="let element" class="data-format items-center">
              {{ element.backTranslation }}%
            </td>
          </ng-container>

          <ng-container matColumnDef="ConsultantProgress">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="header-format"
            >
              Consultant Check
            </th>
            <td mat-cell *matCellDef="let element" class="data-format items-center">
              {{ element.consultantCheck }}%
            </td>
          </ng-container>

          <ng-container matColumnDef="CitProgress">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="header-format"
            >
              CIT Check
            </th>
            <td mat-cell *matCellDef="let element" class="data-format items-center">
              {{ element.citCheck }}%
            </td>
          </ng-container>

          <ng-container matColumnDef="MasterRecordingProgress">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="header-format"
            >
              Master Recording
            </th>
            <td mat-cell *matCellDef="let element" class="data-format items-center">
              {{ element.masterRecord }}%
            </td>
          </ng-container>
          <ng-container matColumnDef="PublishingProgress">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="header-format"
            >
              Publishing
            </th>
            <td mat-cell *matCellDef="let element" class="data-format">
              {{ element.publishing }}%
            </td>
          </ng-container>
          <ng-container matColumnDef="Action">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="header-format"
            >
              Action
            </th>
            <td mat-cell *matCellDef="let element" class="data-format">
              <button
                (click)="showProgressModel(element)"
                class="background-primary btn-primary rounded-sm cursor-pointer s"
              >
                Show Progress
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <div [hidden]="books && books.length <= 5" class="text-primary">
          <mat-paginator
            class="mat-paginator-outer-container"
            [pageSize]="20"
            [pageSizeOptions]="[5, 10, 20, 30, 40, 50]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
    </div>
    <div class="no-projects back-main" *ngIf="!books">
      <h3>Books not found</h3>
    </div>
    <div class="back-main">
      <button
        class="background-primary btn-primary rounded-sm cursor-pointer py-2 px-4"
        (click)="backToProjectList()"
      >
        Back to list
      </button>
    </div>
  </mat-card>
</ng-container>
