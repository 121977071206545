import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { PopUpModalComponent } from 'src/app/components/pop-up-modal/pop-up-modal.component';
import { BibleBooksService } from 'src/app/services/bible-book/bible-book.service';
import { DialogsService } from 'src/app/services/dialog/dialog.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { ProjectProfileService } from 'src/app/services/project-profile/project-profile.service';
import { UserDataService } from 'src/app/services/user-data/user-data.service';

interface IFILECONTENT {
  languageName: string;
  chapter: string;
  testamentType: string;
  bookName: string;
  target?: string;
  type?: string;
}

@Component({
  selector: 'app-upload-audio-files',
  templateUrl: './upload-audio-files.component.html',
  styleUrls: ['./upload-audio-files.component.scss'],
})
export class UploadAudioFilesComponent implements OnInit {
  chapters: Array<string> | any;
  tabInfo: any;
  testamentType: string | any;
  @ViewChild('audioFileInput')
  audioFileInput: ElementRef | any;

  @ViewChild('textFileInput')
  textFileInput: ElementRef | any;

  @ViewChild('consultantFileInput')
  consultantFileInput: ElementRef | any;
  uploadFileForm: FormGroup | any;

  /****************************************
   *  Varaible
   ****************************************/
  audioFiles: Array<File> = [];
  scriptFiles: Array<File> = [];
  consultantNotes: Array<File> = [];
  regions: Array<object> | any;
  countries: Array<object> | any;
  languages: Array<object> | any;
  fileObj: IFILECONTENT | any;
  books: Array<string> | any;
  testaments: Array<string> = ['New testament', 'Old testament'];
  bookJson: Array<object> | any;
  formData: FormData | any;
  fileExtensionError = false;
  isAudioError: boolean | any;
  isScriptError: boolean | any;
  isNotesError: boolean | any;
  allowedFormats: Array<string> | any;
  fileData: any;
  fileData_FileUpload: any;
  isDisplay: boolean = false;
  message: string='';
  count: number = 0;

  /****************************************
   *  Constructor
   ****************************************/
  constructor(
    private userDataService: UserDataService,
    private dialog: MatDialog,
    private dialogsService: DialogsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private projectProfileService: ProjectProfileService,
    private bibleBooksService: BibleBooksService,
    private languageService: LanguageService
  ) {
    this.uploadFileForm = new FormGroup({
      language: new FormControl('', Validators.required),
      bookName: new FormControl('', Validators.required),
      chapter: new FormControl('', Validators.required),
      testamentType: new FormControl('', Validators.required),
      region: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {
    this.formData = new FormData();
    this.getRegions();
  }

  /****************************************
   *  Get Audio Files
   ****************************************/
  async getAudioFiles(fileObj: any) {
    fileObj.type = 'audio';
    fileObj.target = 'source';
    Array.from(this.audioFiles).map(async (file, index) => {
      await this.formData.append('file', file);
      await this.formData.append('fileObj', JSON.stringify(this.fileObj));
    });
  }

  /****************************************
   *  Get Script Files
   ****************************************/
  async getScriptFiles(fileObj: any) {
    fileObj.type = 'script';
    fileObj.target = 'source';
    Array.from(this.scriptFiles).forEach(async (file, index) => {
      await this.formData.append('file', file);
      await this.formData.append('fileObj', JSON.stringify(this.fileObj));
    });
  }

  /****************************************
   *  Get Consultant Notes
   ****************************************/
  async getConsultantNotes(fileObj: any) {
    fileObj.type = 'note';
    fileObj.target = 'source';
    Array.from(this.consultantNotes).map(async (file, index) => {
      await this.formData.append('file', file);
      await this.formData.append('fileObj', JSON.stringify(this.fileObj));
    });
  }

  /****************************************
   *  Get All Files
   ****************************************/
  async getAllFiles(fileObj: any) {
    await this.getAudioFiles(fileObj);
    await this.getScriptFiles(fileObj);
    await this.getConsultantNotes(fileObj);
  }

  /****************************************
   * Select Audio Files
   ****************************************/
  selectAudioFiles(event: any) {
    this.audioFiles = <Array<File>>event.target.files;
    this.isAudioError = false;
    this.allowedFormats = ['.mp3', '.wav'];
    this.isAudioError = this.validateFileFormat(
      this.audioFiles,
      this.allowedFormats
    );
    this.updateFileSelectionError();
  }

  /****************************************
   *  Select Script Files
   ****************************************/
  selectScriptFiles(event: any) {
    this.scriptFiles = <Array<File>>event.target.files;
    this.isScriptError = false;
    this.allowedFormats = ['.txt', '.pdf', '.doc', '.docx'];
    this.isScriptError = this.validateFileFormat(
      this.scriptFiles,
      this.allowedFormats
    );
    this.updateFileSelectionError();
  }

  /****************************************
   *  Select Consultant Files
   ****************************************/
  selectConsultantFiles(event: any) {
    this.consultantNotes = <Array<File>>event.target.files;
    this.isNotesError = false;
    this.allowedFormats = ['.txt'];
    this.isNotesError = this.validateFileFormat(
      this.consultantNotes,
      this.allowedFormats
    );
    this.updateFileSelectionError();
  }

  /****************************************
   *  Get Region
   ****************************************/
  getRegions() {
    this.languageService.getRegions().subscribe((response) => {
      this.regions = this.alphaOrder(response, 'regionName');
    });
  }

  alphaOrder(list: any, fieldName: any) {
    return list.sort((a: any, b: any) => {
      if (a[fieldName] > b[fieldName]) return 1;
      else if (a[fieldName] < b[fieldName]) return -1;
      else return 0;
    });
  }

  /****************************************
   *  Get Countries
   ****************************************/
  getCountries(event: any) {
    this.uploadFileForm.controls.country.reset();
    this.uploadFileForm.controls.language.reset();
    const regionId = event.value.id;
    this.languageService
      .getCountriesByRegion(regionId)
      .subscribe((response) => {
        this.countries = this.alphaOrder(response, 'countryName');
      });
  }

  /****************************************
   *  Get Language
   ****************************************/
  getLanguages(event: any) {
    this.uploadFileForm.controls.language.reset();
    const countryId = event.value.id;
    this.languageService
      .getLanguagesByCountry(countryId)
      .subscribe((response) => {
        this.languages = this.alphaOrder(response, 'languageName');
      });
  }

  /****************************************
   *  Set Tab Info
   ****************************************/
  setTabInfo($event: any) {
    this.tabInfo = $event;
    this.testamentType = this.tabInfo.tab.textLabel.split(' ')[0].toLowerCase();
  }

  /****************************************
   *  Get Books
   ****************************************/
  getBooks($event: any) {
    this.uploadFileForm.controls.chapter.reset();
    this.uploadFileForm.controls.bookName.reset();
    this.bibleBooksService.getBooks().subscribe((response) => {
      this.bookJson = response;
      this.books = Object.keys(response[$event.value]);
      this.books.sort();
    });
  }

  /****************************************
   *  Get Chapters
   ****************************************/
  getChapters($event: any) {
    this.uploadFileForm.controls.chapter.reset();
    this.chapters = Object.keys(
      this.bookJson[this.uploadFileForm.controls.testamentType.value][
        $event.value
      ]
    );
  }

  /****************************************
   *  Uplaod Files
   ****************************************/
  async uploadFiles() {
    this.count++;
    if(this.count>1){
      this.isDisplay=true;
      this.showNotification("please wait for a while")
    }else{
      this.dialogsService.confirm();
      this.fileObj = {
        languageName: this.uploadFileForm.controls.language.value,
        chapter: this.uploadFileForm.controls.chapter.value.toLowerCase(),
        bookName: this.uploadFileForm.controls.bookName.value.toLowerCase(),
        testamentType: this.uploadFileForm.controls.testamentType.value
          .split(' ')[0]
          .toLowerCase(),
      };
      await this.getAllFiles(this.fileObj);
      this.audioFiles = [];
      this.scriptFiles = [];
      //this.consultantNotes = [];
      try {
        this.fileData = await this.userDataService
          .uploadFile(this.formData)
          .toPromise();
        this.fileData_FileUpload = this.fileData.fileUpload;
        if (this.fileData_FileUpload) {
          // if (fileData) {
          this.formData = new FormData();
          this.dialogsService.closeModal();
          this.dialog.open(PopUpModalComponent, {
            data: {
              message: 'File uploaded successfully',
            },
          });
          this.count=0;
        } else {
          this.dialogsService.closeModal();
          this.dialog.open(PopUpModalComponent, {
            data: {
              message: 'File not inserted to Database',
            },
          });
          this.count=0;
        }
        this.resetForm();
      } catch (e) {
        this.dialogsService.closeModal();
        this.dialog.open(PopUpModalComponent, {
          data: {
            message: 'Something went wrong',
          },
        });
        console.log(e);
        this.count=0;
      }
    }
  }

  /****************************************
   *  Reset Form
   ****************************************/
  resetForm() {
    this.uploadFileForm.controls.chapter.reset();
    this.audioFileInput.nativeElement.value = '';
    this.textFileInput.nativeElement.value = '';
  }

  /****************************************
   * Redirect to Home Page
   ****************************************/
  homePage() {
    this.router.navigateByUrl('');
  }

  /****************************************
   *  Check File Extension
   ****************************************/
  checkFileExtension(fileName: any, allowedFileFormat: any) {
    const fileExtension = fileName.substring(
      fileName.lastIndexOf('.'),
      fileName.length
    );
    const isValidFile = !(
      allowedFileFormat.indexOf(fileExtension.toLowerCase()) > -1
    );
    return isValidFile;
  }

  /****************************************
   *  Validate the Form and File Format
   ****************************************/
  validateFileFormat(files: any, allowedFileFormat: any) {
    for (const file of files) {
      const isInvalidFile = this.checkFileExtension(
        file.name,
        allowedFileFormat
      );
      if (isInvalidFile) {
        return isInvalidFile;
      }
    }

    return;
  }

  /****************************************
   *  Uplaod File Selection Error
   ****************************************/
  updateFileSelectionError() {
    this.fileExtensionError =
      this.isAudioError || this.isScriptError || this.isNotesError;
  }

  showNotification(msg: any) {
    this.message = msg;
    setTimeout(() => {
      this.isDisplay = false;
      this.message = '';
    }, 1500);
  }
}