import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import axios from 'axios';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
  ) { }

  getAllRequest(): Observable<any> {
    return this.http.get(
      `${environment.services.rhemaServer}/support/get-request-details`
    );
  }

  updateSupportStatus(id: any, status: any): Observable<any> {
    return this.http.post(
      `${environment.services.rhemaServer}/support/updateSupportStatus`,
      { id, status }
    );
  }

  sendSupportApprovalNotification(data: any): Observable<any> {
    return this.http.post(
      `${environment.services.rhemaServer}/support/sendSupportApprovalNotification`,
      data
    );
  }

  async getAwsFiles(fileId: String, fileName: String) {
    let res = await axios.get(
      `${environment.supportURL}${fileId}/${fileName}`,
      {
        decompress: false,
        responseType: "arraybuffer",
      }
    );
    return (res)
  }


}
