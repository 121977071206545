import { Component, OnInit,ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, } from '@angular/material/table';
import { MatDialog,MatDialogConfig } from '@angular/material/dialog';

import { NavigationEnd, Router } from '@angular/router';
import { SupportService } from 'src/app/services/support/support.service';
import { SupportAttachViewComponent } from '../support-attach-view/support-attach-view.component';
import { DialogsService } from 'src/app/services/dialog/dialog.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  adminLoggedIn: any;
  currentRole: any;
  itemSerialNumber: any;
  isDisplay: boolean = false;
  allRequest: any = [];
  dataSource: any;
  role: string | any;
  search: any;
  books: any;
  displayedColumns: string[] = [
    'position',
    'userName',
    'email',
    'TypeOfBug',
    'status',
    'attachment',
  ];
  checkAdmin: any;

  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  sendSupport: any;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private dialogService: DialogsService,
    private supportService: SupportService
  ) {
    
  }

  async ngOnInit() {
    this.dialogService.confirm();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    });
    this.role = localStorage.getItem('role');
    this.getAllResquest();
    // if (this.adminLoggedIn && this.currentRole !== 'Coordinator') {
    //   this.getProjectsAll();
    // } else if (this.adminLoggedIn && this.currentRole === 'Coordinator') {
    //   this.getCoordinatorProjects();
    // }
   

  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  /****************************
   *  Filter Project
   *****************************/
  filterProjects(search: any) {
    const allRequest = this.allRequest.filter((request: any) => 
    request.email.includes(search) || request.typeOfSsupport.includes(search));
    this.dataSource = new MatTableDataSource(allRequest);
  }

  /****************************
   *  Change page
   *****************************/
  changePage(evn: any) {
    this.itemSerialNumber = evn.pageSize * evn.pageIndex + 1;
  }

  /****************************
   *  Get Books Percentage
   *****************************/
 

  getAllResquest() {
    this.supportService
      .getAllRequest()
      .subscribe(async (requests) => {
        this.allRequest = requests.data                       
        this.isDisplay = true;
        await wait();
        this.dataSource = new MatTableDataSource<void>(this.allRequest);
        this.dataSource.paginator = this.paginator;
      });
      this.dialogService.closeModal();
  }


  openDialog(typeOfSsupport:any,description:any, attach:[],id:any,status: any) {    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
     id:id,
     status:status,
     data:attach,
     typeOfBug:typeOfSsupport,
     description:description
    };
    dialogConfig.height="70%";
    dialogConfig.width="100%"
    const dialogRef = this.dialog.open(SupportAttachViewComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.sendSupport=this.allRequest[0];
      data.isCheck==true ? 
      (this.dataSource = new MatTableDataSource<void>([data]),
      this.supportService.sendSupportApprovalNotification([{...this.sendSupport,status:data.status}]).subscribe((res)=>{        
      })) :
      this.dataSource = new MatTableDataSource<void>(this.allRequest)
      this.dialogService.closeModal();
    });
  }

  
}

const wait = async () => {
  let newPromise = await new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, 100);
  });

  return newPromise;
};
