import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GraphsService {
  constructor(private http: HttpClient) {}

  getDashboardGraphs(): Observable<any> {
    return this.http.get(`${environment.services.rhemaServer}/analytics`);
  }

  getUserGraphs(userId: any, userRole: any): Observable<any> {
    return this.http.get(
      `${environment.services.rhemaServer}/analytics/userAnalytics?userId=${userId}&userRole=${userRole}`
    );
  }

  getUserSessions(userId:any):Observable<any>{
    return this.http.get(`${environment.services.rhemaServer}/userSession/user/${userId}`)
  }
}
