<div class="description" *ngIf="!isProjectList">
    <span class="start-text">Do you want to delete the</span>
    <span class="start-text text-space">user <span class="user-name"> {{user?.firstName}}</span></span>
    <span class="end-text"> from list? </span>
</div>
<div class="buttons" *ngIf="!isProjectList">
    <button class="delete-btn" (click)="deleteUser(user)">Delete</button>
    <button class="delete-btn cancel" (click)="closeModel()">Cancel</button>
</div>

<div class="projectDiv" *ngIf="isProjectList">
    <table *ngIf="ProjectData.length">
        <thead>
        <tr>
            <th>Project Name</th>
            <th>Language</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let projet of ProjectData"> 
            <td>{{projet.projectName}}</td>
            <td>{{projet.language}}</td>
        </tr>
        </tbody>
    </table>
    <h3 *ngIf="ProjectData.length < 1">No Projects Found!</h3>
</div>