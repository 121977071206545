<mat-card class="file-upload-wrap font-primary">
  <mat-card-header class="background-primary border-top-5">
    <mat-card-title>Upload Source Files for Jesus</mat-card-title>
  </mat-card-header>
  <form [formGroup]="uploadFileForm" #heroForm="ngForm">
    <div class="file-upload-container">
      <div class="grid grid-cols-2 sm-grid-cols-1 grill">
        <mat-form-field class="proportion mr-12">
          <mat-label>Select Region</mat-label>
          <mat-select formControlName="region" required ngDefaultControl (selectionChange)="getCountries($event)">
            <mat-option *ngFor="let region of regions" [value]="region" class="mat-option-custom">
              {{ region.regionName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="uploadFileForm.controls.region.errors?.required">
            Please select region
          </mat-error>
        </mat-form-field>
        <mat-form-field class="proportion">
          <mat-label>Select Country</mat-label>
          <mat-select formControlName="country" required ngDefaultControl (selectionChange)="getLanguages($event)">
            <mat-option *ngFor="let country of countries" [value]="country" class="mat-option-custom">
              {{ country.countryName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="uploadFileForm.controls.country.errors?.required">
            Please select country
          </mat-error>
        </mat-form-field>
      </div>
      <div class="grid grid-cols-2 sm-grid-cols-1 grill">
        <mat-form-field class="proportion mr-12">
          <mat-label>Select Source Language</mat-label>
          <mat-select formControlName="language" required ngDefaultControl>
            <mat-option *ngFor="let language of languages" [value]="language.languageName" class="mat-option-custom">
              {{ language.languageName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="uploadFileForm.controls.language.errors?.required">
            Please select language
          </mat-error>
        </mat-form-field>
        <mat-form-field class="proportion">
          <mat-label>Select Stage Name</mat-label>
          <mat-select formControlName="storyName" required ngDefaultControl>
            <mat-option *ngFor="let story of storyList" [value]="story" class="mat-option-custom">
              {{ story }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="uploadFileForm.controls.storyName.errors?.required">
            Please select Stage
          </mat-error>
        </mat-form-field>
      </div>
      <div class="grid grid-cols-2 sm-grid-cols-1 text-center">
        <div class="example-container" class="proportion">
          <!-- <label class="btn btn-default btn-headding">Upload Audio
                        <input #audioFileInput type="file" accept=".wav,.mp3"
                               (change)="selectAudioFiles($event)"
                               multiple>
                    </label> -->
          <!-- <br>
                     <span *ngIf="isAudioError" class="error">Invalid Selection(only .mp3,.wav allowed)</span> -->
          <span class="text-gray">Source Video </span>
          <br />
          <label class="btn-primary py-0 px-2 rounded-sm mat-raised-button">Upload Source Video
            <input #videoFileInput hidden type="file" (change)="selectVideoFiles($event)" accept=".mp4" multiple />
          </label><br />
          <span *ngIf="isVideoError" class="error">Invalid Selection(only .mp4 allowed)</span>
          <br />
          <span *ngIf="!isVideoError">
            <span *ngFor="let video of videoFiles" class="list-no-decoration">
              {{ video.name }}
            </span>
          </span>
        </div>
        <div class="example-container" class="proportion">
          <span class="text-gray">Source Script </span>
          <br />
          <label class="btn-primary py-0 px-2 rounded-sm mat-raised-button">Upload Source Script
            <input hidden #textFileInput type="file" (change)="selectScriptFiles($event)"
              accept=".txt , .doc ,.docx , .pdf" multiple /> </label><br />
          <span *ngIf="isScriptError" class="error">Invalid Selection(only .txt , .pdf ,.doc , .docx allowed)</span>
          <br />
          <span *ngIf="!isScriptError">
            <span *ngFor="let script of scriptFiles" class="list-no-decoration">
              {{ script.name }}
            </span>
          </span>
        </div>
        <!-- <div class="example-container" class="proportion">
                    <span class="text-gray">Consultant Notes</span>
                    <br />
                    <label class="btn-primary py-0 px-2 rounded-sm  mat-raised-button ">Upload Consultant Notes
                        <input hidden #consultantFileInput type="file" (change)="selectConsultantFiles($event)"
                            accept=".txt" multiple>
                    </label><br>
                    <span *ngIf="isNotesError" class="error">Invalid Selection(only .txt allowed)</span> <br>
                    <span *ngIf="!isNotesError">
                        <span *ngFor="let notes of consultantNotes" class="list-no-decoration">
                            {{notes.name}}
                        </span>
                    </span>
                </div> -->
      </div>
      <div class="button-row pt-2">
        <button mat-raised-button type="submit" (click)="
            uploadFiles();
            uploadFileForm['submitted'] = false;
            heroForm.resetForm()
          " [disabled]="
            (videoFiles.length ||
              scriptFiles.length ||
              consultantNotes.length) &&
            (uploadFileForm.invalid || fileExtensionError)
          " [ngClass]="{
            'btn-primary rounded-sm':
              (videoFiles.length ||
                scriptFiles.length ||
                consultantNotes.length) &&
              uploadFileForm.valid &&
              !fileExtensionError
          }">
          Upload File
        </button>
      </div>
    </div>
  </form>
</mat-card>