import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PopUpModalComponent } from 'src/app/components/pop-up-modal/pop-up-modal.component';
import { DialogsService } from 'src/app/services/dialog/dialog.service';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'app-create-language',
  templateUrl: './create-language.component.html',
  styleUrls: ['./create-language.component.scss']
})
export class CreateLanguageComponent implements OnInit {

  createLanguageForm: FormGroup | any;
  resetForm: FormGroup | any;
  regions: Array<any> | any;
  countries: Array<any> | any;
  constructor(private languageService: LanguageService,
    private dialog: MatDialog,
    private dialogService: DialogsService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.createLanguageControls();
  }

  createLanguageControls() {
    this.createLanguageForm = new FormGroup({
      region: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      language: new FormControl('', Validators.required)
    });
    this.getRegions();
  }

  getRegions() {
    this.languageService.getRegions().subscribe((response) => {
      this.regions=this.alphaOrder(response,'regionName');
    });
  }

  getCountries(event: any) {
    this.createLanguageForm.controls['country'].setValue(null);
    const regionId = event.value.id;
    this.languageService.getCountriesByRegion(regionId).subscribe((response) => {
      this.countries = this.alphaOrder(response,'countryName');
    });
  }

  alphaOrder(list: any,fieldName: any){    
     return list.sort((a: any,b: any)=>{
     if(a[fieldName]>b[fieldName]) return 1;
     else if (a[fieldName]<b[fieldName]) return -1;
     else return 0;
   });      
 }


  trackByIndex(index: any) {
    return index;
  }

  createLanguage() {
    const request = {
      language: this.createLanguageForm.value.language.toLowerCase().trim(),
      region: this.createLanguageForm.value.region.regionName,
      country: this.createLanguageForm.value.country.countryName
    };
    this.dialogService.confirm();
    this.languageService.createLanguage(request).subscribe((response) => {
      if (response.success) {
        this.dialogService.closeModal();
        this.dialog.open(PopUpModalComponent, {
          data: {
            message: 'Language Created Successfully'
          }
        });
      }
      this.createLanguageForm.reset();
    }, (err) => {
      this.dialogService.closeModal();
      this.dialog.open(PopUpModalComponent, {
        data: {
          message: err.error.error
        }
      });
    });
  }

  

}
