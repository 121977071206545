<mat-card class="mat-card reset-form font-primary">
    <mat-card-header class="background-primary border-top-5">
        <mat-card-title>Reset Password</mat-card-title>
    </mat-card-header>
    <form [formGroup]="resetForm">
        <mat-card-content>
            <mat-form-field color="accent">
                <input matInput placeholder="Email" formControlName="email" autofocus required>
                <mat-error *ngIf="resetForm.controls.email.errors?.required">Please enter a valid email</mat-error>
            </mat-form-field>
            <mat-form-field color="accent">
                <input [type]="newPasswordIcon ? 'text' : 'password'" matInput placeholder="New Password"
                    formControlName="newPassword" required>
                <mat-icon matSuffix (click)="newPasswordIcon=!newPasswordIcon" class="visible">
                    {{newPasswordIcon ? 'visibility': 'visibility_off' }}
                </mat-icon>
                <mat-error *ngIf="resetForm.controls.newPassword.errors?.required">Please enter new password
                </mat-error>
            </mat-form-field>
            <mat-form-field color="accent">
                <input [type]="confirmPasswordIcon ? 'text' : 'password'" matInput placeholder="Confirm Password"
                    formControlName="confirmPassword" required>
                <mat-icon matSuffix (click)="confirmPasswordIcon=!confirmPasswordIcon" class="visible">
                    {{confirmPasswordIcon ? 'visibility': 'visibility_off' }}
                </mat-icon>
                <mat-error *ngIf="resetForm.controls.confirmPassword.errors?.required">Please enter confirm password
                </mat-error>
            </mat-form-field>
            <mat-error *ngIf="newpassword.value && confirmPassword.value && showErrormsg">
                new password and confirm password doesn't matches
            </mat-error>

            <div class="button-row">
                <button mat-raised-button [ngClass]="{'btn-primary rounded-sm': resetForm.valid}" class="mr-3"
                    [disabled]="!resetForm.valid" (click)="reset(resetForm)">Reset Password
                </button>
            </div>
        </mat-card-content>
    </form>


</mat-card>