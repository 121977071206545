import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { PopUpModalComponent } from 'src/app/components/pop-up-modal/pop-up-modal.component';
import { BibleBooksService } from 'src/app/services/bible-book/bible-book.service';
import { DialogsService } from 'src/app/services/dialog/dialog.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { ProjectProfileService } from 'src/app/services/project-profile/project-profile.service';
import { UserDataService } from 'src/app/services/user-data/user-data.service';

interface IFILECONTENT {
  languageName: string;
  chapter?: string;
  testamentType?: string;
  bookName?: string;
  target?: string;
  type?: string;
  story?: string;
}

@Component({
  selector: 'app-upload-video-files',
  templateUrl: './upload-video-files.component.html',
  styleUrls: ['./upload-video-files.component.scss'],
})
export class UploadVideoFilesComponent implements OnInit {
  chapters: Array<string> | any;
  tabInfo: any;
  testamentType: string | any;
  // @ViewChild('audioFileInput')
  // audioFileInput: ElementRef;
  @ViewChild('videoFileInput')
  videoFileInput: ElementRef | any;
  @ViewChild('textFileInput')
  textFileInput: ElementRef | any;
  @ViewChild('consultantFileInput')
  consultantFileInput: ElementRef | any;
  uploadFileForm: FormGroup | any;
  // audioFiles: Array<File> = [];
  videoFiles: Array<File> = [];
  scriptFiles: Array<File> = [];
  consultantNotes: Array<File> = [];
  regions: Array<object> | any;
  countries: Array<object> | any;
  languages: Array<object> | any;
  fileObj: IFILECONTENT | any;
  books: Array<string> | any;
  //testaments: Array<string> = ['New testament', 'Old testament'];
  storyList: Array<string> = [
    'Birth of Jesus',
    'Life of Jesus',
    'Crucifiction',
  ].sort();
  bookJson: Array<object> | any;
  formData: FormData | any;
  fileExtensionError = false;
  //isAudioError: boolean;
  isVideoError: boolean | any;
  isScriptError: boolean | any;
  isNotesError: boolean | any;
  allowedFormats: Array<string> | any;
  fileData: any;
  fileData_fileUpload: any;

  constructor(
    private userDataService: UserDataService,
    private dialog: MatDialog,
    private dialogsService: DialogsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private projectProfileService: ProjectProfileService,
    private bibleBooksService: BibleBooksService,
    private languageService: LanguageService
  ) {
    this.uploadFileForm = new FormGroup({
      language: new FormControl('', Validators.required),
      // bookName: new FormControl('', Validators.required),
      // chapter: new FormControl('', Validators.required),
      region: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      storyName: new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {
    this.formData = new FormData();
    this.getRegions();
  }

  // async getAudioFiles(fileObj) {
  //   fileObj.type = 'audio';
  //   fileObj.target = 'source';
  //   Array.from(this.audioFiles).map(async (file, index) => {
  //     await this.formData.append('file', file);
  //     await this.formData.append('fileObj', JSON.stringify(this.fileObj));
  //   });
  // }

  async getVideoFiles(fileObj: any) {
    fileObj.type = 'video';
    fileObj.target = 'source';
    Array.from(this.videoFiles).map(async (file, index) => {
      await this.formData.append('file', file);
      await this.formData.append('fileObj', JSON.stringify(this.fileObj));
    });
  }

  async getScriptFiles(fileObj: any) {
    fileObj.type = 'script';
    fileObj.target = 'source';
    Array.from(this.scriptFiles).forEach(async (file, index) => {
      await this.formData.append('file', file);
      await this.formData.append('fileObj', JSON.stringify(this.fileObj));
    });
  }

  async getConsultantNotes(fileObj: any) {
    fileObj.type = 'note';
    fileObj.target = 'source';
    Array.from(this.consultantNotes).map(async (file, index) => {
      await this.formData.append('file', file);
      await this.formData.append('fileObj', JSON.stringify(this.fileObj));
    });
  }

  async getAllFiles(fileObj: any) {
    //await this.getAudioFiles(fileObj);
    await this.getVideoFiles(fileObj);
    await this.getScriptFiles(fileObj);
    await this.getConsultantNotes(fileObj);
  }

  // selectAudioFiles(event) {
  //   this.audioFiles = <Array<File>>event.target.files;
  //   this.isAudioError = false;
  //   this.allowedFormats = ['.mp3', '.wav'];
  //   this.isAudioError = this.validateFileFormat(this.audioFiles, this.allowedFormats);
  //   this.updateFileSelectionError();
  // }

  selectVideoFiles(event: any) {
    console.log('Video ', event.target.files);
    this.videoFiles = <Array<File>>event.target.files;
    this.isVideoError = false;
    this.allowedFormats = ['.mp4'];
    this.isVideoError = this.validateFileFormat(
      this.videoFiles,
      this.allowedFormats
    );
    this.updateFileSelectionError();
  }

  selectScriptFiles(event: any) {
    console.log('Script ', event.target.files);
    this.scriptFiles = <Array<File>>event.target.files;
    this.isScriptError = false;
    this.allowedFormats = ['.txt', '.pdf', '.doc', 'docxÏ'];
    this.isScriptError = this.validateFileFormat(
      this.scriptFiles,
      this.allowedFormats
    );
    this.updateFileSelectionError();
  }

  selectConsultantFiles(event: any) {
    this.consultantNotes = <Array<File>>event.target.files;
    this.isNotesError = false;
    this.allowedFormats = ['.txt'];
    this.isNotesError = this.validateFileFormat(
      this.consultantNotes,
      this.allowedFormats
    );
    this.updateFileSelectionError();
  }

  getRegions() {
    this.languageService.getRegions().subscribe((response) => {
      this.regions = this.alphaOrder(response, 'regionName');
      // response;
    });
  }

  getCountries(event: any) {
    this.uploadFileForm.controls.country.reset();
    this.uploadFileForm.controls.language.reset();
    const regionId = event.value.id;

    this.languageService
      .getCountriesByRegion(regionId)
      .subscribe((response) => {
        this.countries = response;
      });
  }

  getLanguages(event: any) {
    this.uploadFileForm.controls.language.reset();
    const countryId = event.value.id;
    this.languageService
      .getLanguagesByCountry(countryId)
      .subscribe((response) => {
        this.languages = this.alphaOrder(response, 'languageName');
      });
  }

  setTabInfo($event: any) {
    this.tabInfo = $event;
    this.testamentType = this.tabInfo.tab.textLabel.split(' ')[0].toLowerCase();
  }

  alphaOrder(list: any, fieldName: any) {
    // console.log(fieldName);
    return list.sort((a: any, b: any) => {
      if (a[fieldName] > b[fieldName]) return 1;
      else if (a[fieldName] < b[fieldName]) return -1;
      else return 0;
    });
  }
  // getBooks($event) {
  //   this.uploadFileForm.controls.chapter.reset();
  //   this.uploadFileForm.controls.bookName.reset();
  //   this.bibleBooksService.getBooks().subscribe((response) => {
  //     this.bookJson = response;
  //     this.books = Object.keys(response[$event.value]);
  //   });
  // }

  // getChapters($event) {
  //   this.uploadFileForm.controls.chapter.reset();
  //   this.chapters = Object.keys(this.bookJson[this.uploadFileForm.controls.testamentType.value][$event.value]);
  // }

  async uploadFiles() {
    this.dialogsService.confirm();
    this.fileObj = {
      languageName: this.uploadFileForm.controls.language.value,
      //chapter: this.uploadFileForm.controls.chapter.value.toLowerCase(),
      //bookName: this.uploadFileForm.controls.bookName.value.toLowerCase(),
      //testamentType: this.uploadFileForm.controls.testamentType.value.split(' ')[0].toLowerCase()
      bookName: 'jesus film',
      chapter: this.uploadFileForm.controls.storyName.value.toLowerCase(),
      story: this.uploadFileForm.controls.storyName.value.toLowerCase(),
    };
    await this.getAllFiles(this.fileObj);
    try {
      this.fileData = await this.userDataService
        .uploadFile(this.formData)
        .toPromise();
      if (this.fileData.fileUpload) {
        this.formData = new FormData();
        this.dialogsService.closeModal();
        this.dialog.open(PopUpModalComponent, {
          data: {
            message: 'File uploaded successfully',
          },
        });
        this.resetForm();
      } else {
        this.dialogsService.closeModal();
        this.dialog.open(PopUpModalComponent, {
          data: {
            message: 'File not inserted to Database',
          },
        });
      }
    } catch (e) {
      this.dialogsService.closeModal();
      this.dialog.open(PopUpModalComponent, {
        data: {
          message: 'Something went wrong',
        },
      });
      console.log(e);
    }
  }

  resetForm() {
    this.uploadFileForm.reset();
    this.videoFileInput.nativeElement.value = '';
    this.textFileInput.nativeElement.value = '';
  }

  homePage() {
    this.router.navigateByUrl('');
  }

  checkFileExtension(fileName: any, allowedFileFormat: any) {
    const fileExtension = fileName.substring(
      fileName.lastIndexOf('.'),
      fileName.length
    );
    const isValidFile = !(
      allowedFileFormat.indexOf(fileExtension.toLowerCase()) > -1
    );
    return isValidFile;
  }

  validateFileFormat(files: any, allowedFileFormat: any) {
    for (const file of files) {
      const isInvalidFile = this.checkFileExtension(
        file.name,
        allowedFileFormat
      );
      if (isInvalidFile) {
        return isInvalidFile;
      }
    }
    return;
  }

  updateFileSelectionError() {
    this.fileExtensionError =
      this.isVideoError ||
      this.isScriptError ||
      this.isNotesError ||
      this.isVideoError;
  }
}
