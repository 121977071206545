import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UserDataService } from 'src/app/services/user-data/user-data.service';
import { MatPaginator } from '@angular/material/paginator';
import { DialogsService } from 'src/app/services/dialog/dialog.service';

@Component({
  selector: 'app-timeline-extension',
  templateUrl: './timeline-extension.component.html',
  styleUrls: ['./timeline-extension.component.scss'],
})
export class TimelineExtensionComponent implements OnInit {
  itemSerialNumber: any;
  dataSource: any;
  displayedColumns: string[] = [
    'position',
    'projectName',
    'userName',
    'language',
    'stageName',
    'bookName',
    'BookNoOfDays',
    'chapterName',
    'ChapterNoOfDays',
    'approval',
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  transId: any;
  timelineRequest: any;
  transEmail: any;

  constructor(
    private dialogService: DialogsService,
    private userDataService: UserDataService
  ) {}

  ngOnInit(): void {
    this.loadTimeLineExtend();
  }

  async loadTimeLineExtend() {
    //this.dialogService.confirm();
    this.userDataService
      .getUserByEmail(localStorage.getItem('email'))
      .subscribe((res) => {
        this.transId=res.id;
        this.transEmail=res.email;
        this.userDataService
          .getTimeLineExtendUser(res.id)
          .subscribe((response) => {
            this.timelineRequest=response;
            this.dialogService.confirm();
            this.dataSource = new MatTableDataSource(response);
            this.dataSource.paginator = this.paginator;
            this.dialogService.closeModal();
          });
      });
    this.itemSerialNumber = 1;
  }
  async approvalTimeLineExtend(value: any) {
    this.dialogService.confirm();
    let data = {
      timeLineExtendId: value._id,
      updatedBy:this.transId,
      transEmail:this.transEmail,
      projectName: value.projectName.projectName,
      projectId:value.projectName.id,
      userName: `${value.userName.firstName} ${value.userName.lastName}`,
      userId: value.userName.id,
      language: value.language.language,
      stageName: value.stageName,
      bookName: value.bookName,
      BookNoOfDays: value.BookNoOfDays,
      chapterName: value.chapterName,
      ChapterNoOfDays: value.ChapterNoOfDays,
      approval: true,
    };

    this.userDataService.approvalTimeLineExtend(data).subscribe(async (response) => {
      this.loadTimeLineExtend();
    });
    this.dialogService.closeModal();
  }

  changePage(evn: any) {
    this.itemSerialNumber = evn.pageSize * evn.pageIndex + 1;
  }
}
