import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import {
  MatSidenav,
  MatSidenavContainer,
  MatSidenavContent,
} from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AdminLoginService } from 'src/app/services/login/adminlogin.service';

@Component({
  selector: 'app-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.scss'],
})
export class SideNavbarComponent implements OnInit, OnDestroy {
  mobileQuery: MediaQueryList;
  adminLoggedData: any;
  adminLoggedIn: any;
  role: any;
  checkAdmin: any;
  isExpanded: boolean = true;
  isSign: boolean= true;
  chatbotNavExpanded: boolean = false;
  uploadFilesExpanded: boolean = false;
  linkEnable: boolean = false;
  linkEnable1: boolean = false;
  email:any ="";
  shouldRun = /(^|.)(stackblitz|webcontainer).(io|com)$/.test(
    window.location.host
  );

  private _mobileQueryListener: () => void;
  constructor(
    private router: Router,
    private adminLoginService: AdminLoginService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.role = localStorage.getItem('role');
    this.email= localStorage.getItem("email")
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  logOut() {
    const userLoggedInData = {
      loggedIn: false,
      role: 'SuperAdmin',
    };
    this.adminLoginService.isLoggedIn = userLoggedInData;

    this.adminLoggedData = this.adminLoginService.adminIsLoggedIn;
    this.adminLoggedIn = this.adminLoggedData.loggedIn;
    this.role = this.adminLoggedData.role;

    localStorage.setItem('checkAdmin', this.adminLoggedIn.toString());
    localStorage.setItem('role', this.role);
    localStorage.setItem('isUserLoggedIn', 'false');
    this.adminLoginService.emitToSubscribers();
    localStorage.clear();

    localStorage.setItem('isUserLoggedIn', 'false');
    this.router.navigateByUrl('');
  }

  redirectToHome() {
    this.router.navigateByUrl('/dashboard');
  }

  showDropdownOption(value: any) {
    if (value == 'chatbotNavExpanded') {
      this.chatbotNavExpanded = !this.chatbotNavExpanded;
    } else if (value == 'uploadFilesExpanded') {
      this.uploadFilesExpanded = !this.uploadFilesExpanded;
    }
  }
  toggleNavbar() {
    this.isExpanded = !this.isExpanded;
  }

  trans(){
    this.router.navigateByUrl('/timeline-extension');
  }

  // perform() {
  //   // window.alert('UnAuthorized');
  //   this.router.navigateByUrl('/performance');
  // }
}
