import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmationAlertComponent } from 'src/app/components/confirmation-alert/confirmation-alert.component';
import { DialogsService } from 'src/app/services/dialog/dialog.service';
import { ProjectProfileService } from 'src/app/services/project-profile/project-profile.service';
import {
  TabInfo,
  UserDataService,
} from 'src/app/services/user-data/user-data.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnInit {
  usersDetails: any = [];
  tabInfo: any;
  currentTab = 'MTT';
  selectedIndex = 0;
  displayedColumns: string[] = [
    'Position',
    'UserName',
    'Email',
    'Language',
    'Edit/Delete',
  ];
  dataSource: any;
  itemSerialNumber: any;
  projectData: any;
  subscription: Subscription | any;
  isDisplay: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator | any;

  constructor(
    private userDataService: UserDataService,
    private dialog: MatDialog,
    private dialogService: DialogsService,
    private router: Router,
    private projectProfileService: ProjectProfileService
  ) {
    this.dialogService.confirm();
  }

  ngOnInit(): void {
    this.subscription = this.userDataService.userCurrentTabSubject.subscribe(
      (tabData: TabInfo) => {
        this.currentTab = tabData.tab;
        this.selectedIndex = tabData.index;
        // this.paginator.pageIndex = 0;
        this.setLanguageTab();
        this.getUsersData();
      }
    );
    this.itemSerialNumber = 1;
  }

  setLanguageTab() {
    this.displayedColumns =
      this.currentTab === 'Consultant' ||
      this.currentTab === 'CIT' ||
      this.currentTab === 'Technician'
        ? ['Position', 'UserName', 'Email', 'Edit/Delete']
        : ['Position', 'UserName', 'Email', 'Language', 'Edit/Delete'];
  }

  changePage(evn: any) {
    this.itemSerialNumber = evn.pageSize * evn.pageIndex + 1;
  }

  getUsersData() {
    this.userDataService
      .getUsersByRole(this.currentTab)
      .subscribe(async (userDataList) => {
        this.dialogService.closeModal();
        this.usersDetails = userDataList;
        this.isDisplay = true;
        await wait(100);
        this.dataSource = new MatTableDataSource(userDataList);
        this.dataSource.paginator = this.paginator;
      });
  }

  async setTabInfo($event: any) {
    this.dialogService.confirm();
    this.isDisplay = false;
    this.tabInfo = $event;
    this.selectedIndex = $event.index;
    this.currentTab = this.tabInfo.tab.textLabel;
    this.userDataService.setTabInfo(this.currentTab, this.selectedIndex);
    this.itemSerialNumber = 1;
    await wait(500);
    this.dialogService.closeModal();
    this.isDisplay = true;
  }

  openDialog(userData: any): void {
    console.log('User Data', userData._id);
    const dialogRef = this.dialog.open(ConfirmationAlertComponent, {
      width: '450px',
      height: 'auto',
      data: {
        user: userData,
        currentTab: this.currentTab,
        selectedTabIndex: this.selectedIndex,
      },
    });
  }

  openDialogProject(): void {
    const dialogRef = this.dialog.open(ConfirmationAlertComponent, {
      width: '450px',
      height: 'auto',
      data: {
        ProjectData: this.projectData,
        isProjectList: true,
      },
    });
  }

  getProjectList(data: any) {
    if (data.role === 'Facilitator' || data.role === 'MTT') {
      return;
    } else {
      const userData = {
        role: data.role,
        userID: { id: data._id },
      };
      this.projectProfileService
        .getProjectsByRole(userData)
        .subscribe((projectList) => {
          this.projectData = projectList;
          this.openDialogProject();
        });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

const wait = async (time: any) => {
  return await new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
};
