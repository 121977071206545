<ng-container *ngIf="isDisplay">
    <mat-card>
        <div class="main-project-header">
            <h2 class="text-primary dashboard">Support Request Received</h2>
        </div>
        <div [hidden]="!(allRequest && allRequest.length)">
            <div class="search background-primary searchbar">
                <mat-label>
                    Search
                </mat-label>
                <input type="search" placeholder="Search Email or Bug" class="searchBar" [(ngModel)]="search"
                    (ngModelChange)="filterProjects(search)">
            </div>
            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8"
                *ngIf="allRequest && allRequest.length ">
                <ng-container matColumnDef="position">
                    <th mat-header-cell *matHeaderCellDef class="header-format"> S.No</th>
                    <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}}</td>
                </ng-container>
                <ng-container matColumnDef="userName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-format">Name</th>
                    <td mat-cell *matCellDef="let element" class="data-format "> {{element.firstName}} {{element.lastName}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-format">Email</th>
                    <td mat-cell *matCellDef="let element" class="data-format " >
                        {{element.email}}</td>
                </ng-container>
                <ng-container matColumnDef="TypeOfBug">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-format">Type Of Bug</th>
                    <td mat-cell *matCellDef="let element" class="data-format " >
                        {{element.typeOfSsupport}}</td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-format">Status</th>
                    <td mat-cell *matCellDef="let element" class="data-format " >{{element.status}}</td>
                </ng-container>
                <ng-container matColumnDef="attachment">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-format">Action</th>
                    <td mat-cell *matCellDef="let element">
                        <button class="edit-delete cursor-pointer background-primary btn-primary"
                        (click)="openDialog(element.typeOfSsupport,element.description,element.attachment,element._id,element.status)">View
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="settings" *ngIf="adminLoggedIn && currentRole !== 'Coordinator'">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-format">Action</th>
                    <td mat-cell *matCellDef="let element">
                        <button class="edit-delete cursor-pointer background-primary btn-primary"
                            [routerLink]="['/edit-project',element._id]">Edit
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div [hidden]="allRequest && allRequest.length <=5">
                <mat-paginator 
                (page)="changePage($event)" 
                class="mat-paginator-outer-container"
                [pageSizeOptions]="[5,10,20,30,40,50]" 
                [pageSize]="20"
                showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </mat-card>
    <mat-card class="no-projects" *ngIf="allRequest && allRequest.length < 1">
        <h3>Projects Not Found!</h3>
    </mat-card>

</ng-container>