import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { PieChartComponent } from 'src/app/components/pie-chart/pie-chart.component';
import {
  BibleBooksService,
  ProjectLanguageType,
} from 'src/app/services/bible-book/bible-book.service';
import { DialogsService } from 'src/app/services/dialog/dialog.service';

@Component({
  selector: 'app-books',
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.scss'],
})
export class BooksComponent implements OnInit {
  project: any;
  adminLoggedIn: any;
  currentRole: any;
  language: any;
  type: any;
  isDisplay: any;
  dataSource: any;
  // displayedColumns: string[] = ['Books', 'FacilitatorProgress', 'CommunityCheckProgress',
  //   'ConsultantProgress', 'MasterRecordingProgress', 'Action'];
  displayedColumns: string[] = [
    'Books',
    'Draft',
    'TeamCheckProgress',
    'CommunityCheckProgress',
    'Back Translation',
    'ConsultantProgress',
    'CitProgress',
    'MasterRecordingProgress',
    'PublishingProgress',
    'Action',
  ];
  books: any;
  checkAdmin: any;

  @ViewChild(MatPaginator) paginator: MatPaginator | any;

  constructor(
    private bibleBooksService: BibleBooksService,
    private dialogService: DialogsService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.checkAdmin = localStorage.getItem('checkAdmin');
    this.adminLoggedIn = JSON.parse(this.checkAdmin);
    this.currentRole = localStorage.getItem('role');
    this.dialogService.confirm();
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    });
    this.bibleBooksService.projectLanguageTypeSubject.subscribe(
      (projectInfo: any) => {
        // this.language = projectInfo.language;
        // this.type = projectInfo.targetType;
        this.project=projectInfo.project;
        this.language = 'english';
        this.type = 'source';
      }
    );
    this.getTotalBooksWithPercentage();
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  getTotalBooksWithPercentage() {
    this.bibleBooksService
      .getTranslatedVerse(this.project,this.language, this.type)
      .subscribe(async (data) => {
        this.books = await data;

        this.isDisplay = true;
        await wait();        
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = await this.paginator;
        this.dialogService.closeModal();
      });
  }

  backToProjectList() {
    if (this.adminLoggedIn && this.currentRole === 'Translation Manager') {
      this.router.navigateByUrl('projectListDashboard');
    } else if (this.adminLoggedIn && this.currentRole !== 'Translation Manager') {
      this.router.navigateByUrl('/dashboard');
    }
  }

  showProgressModel(data: any): void {

    const dialogRef = this.dialog.open(PieChartComponent, {
      width: '750px',
      height: 'auto',
      data: {bookProgress:data,contentType:'books'},
    });
  }
}

const wait = async () => {
  let newPromise = await new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      console.log('Wwait s');
      resolve();
    }, 100);
  });

  return newPromise;
};
