<mat-card class="file-upload-wrap font-primary">
  <mat-card-header class="border-top-5 background-primary">
    <mat-card-title> Upload Chatbot MTT Resource Files </mat-card-title>
  </mat-card-header>

  <form [formGroup]="uploadFileForm">
    <div class="file-upload-container">
      <div class="grid grid-cols-2 sm-grid-cols-1 grill">
        <mat-form-field class="proportion">
          <mat-label>Select Region</mat-label>
          <mat-select formControlName="region" required ngDefaultControl (selectionChange)="getCountries($event)">
            <mat-option *ngFor="let region of regions" [value]="region" class="mat-option-custom">
              {{ region.regionName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="uploadFileForm.controls.region.errors?.required">
            Please select region
          </mat-error>
        </mat-form-field>
        <mat-form-field class="proportion">
          <mat-label>Select Country</mat-label>
          <mat-select formControlName="country" required ngDefaultControl (selectionChange)="getLanguages($event)">
            <mat-option *ngFor="let country of countries" [value]="country" class="mat-option-custom">
              {{ country.countryName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="uploadFileForm.controls.country.errors?.required">
            Please select country
          </mat-error>
        </mat-form-field>
      </div>
      <div class="grid grid-cols-2 sm-grid-cols-1 grill">
        <mat-form-field class="proportion">
          <mat-label>Select Source Language</mat-label>
          <mat-select formControlName="language" required ngDefaultControl>
            <mat-option *ngFor="let language of languages" [value]="language.languageName" class="mat-option-custom">
              {{ language.languageName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="uploadFileForm.controls.language.errors?.required">
            Please select language
          </mat-error>
        </mat-form-field>
        <mat-form-field class="proportion">
          <mat-label>Select Testament Type</mat-label>
          <mat-select formControlName="testamentType" required ngDefaultControl (selectionChange)="getBooks($event)">
            <mat-option *ngFor="let testament of testaments" [value]="testament" class="mat-option-custom">
              {{ testament }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="uploadFileForm.controls.testamentType.errors?.required">
            Please select Testament Type
          </mat-error>
        </mat-form-field>
      </div>
      <div class="grid grid-cols-2 sm-grid-cols-1 grill">
        <mat-form-field class="proportion">
          <mat-label>Select Book</mat-label>
          <mat-select formControlName="bookName" required ngDefaultControl (selectionChange)="getChapters($event)">
            <mat-option *ngFor="let book of books" [value]="book" class="mat-option-custom">
              {{ book }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="uploadFileForm.controls.bookName.errors?.required">
            Please select Book
          </mat-error>
        </mat-form-field>
        <mat-form-field class="proportion">
          <mat-label>Select Chapter</mat-label>
          <mat-select formControlName="chapter" required ngDefaultControl>
            <mat-option *ngFor="let chapter of chapters" [value]="chapter" class="mat-option-custom">
              {{ chapter }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="uploadFileForm.controls.chapter.errors?.required">
            Please select Chapter
          </mat-error>
        </mat-form-field>
      </div>
      <!-- <div class="file-container"> -->
      <!-- <div class="example-container" class="proportion">
                    <label class="btn btn-default btn-headding">Upload Source Audio
                        <input #audioFileInput type="file" accept=".wav,.mp3"
                               (change)="selectAudioFiles($event)"
                               multiple>
                    </label>
                    <br>
                    <span *ngIf="isAudioError" class="error">Invalid Selection(only .mp3,.wav allowed)</span>
                </div> -->

      <div class="example-container proportion flex items-center justify-center py-4 overflow-y-hidden">
        <div>
          <div>
            <span class="flex justify-center">Script Resource</span>
          </div>
          <div class="flex justify-center">
            <label class="btn-primary py-0 px-2 rounded-sm mat-raised-button">Upload Chatbot Script Resource
              <br />
              <input hidden #textFileInput type="file" (change)="selectScriptFiles($event)"
                accept=".txt , .doc , .docx , .pdf" multiple />
            </label>
          </div>
          <div>
            <span *ngIf="isScriptError" class="error">Invalid Selection(only .txt allowed)</span>

            <span *ngIf="!isScriptError">
              <span *ngFor="let file of scriptFiles" class="list-no-decoration">
                {{ file.name }}
              </span>
            </span>
          </div>
        </div>
      </div>
      <!-- <div class="example-container" class="proportion">
                    <label class="btn btn-default btn-headding">Upload Consultant Notes
                        <input #consultantFileInput type="file" (change)="selectConsultantFiles($event)" accept=".txt"
                               multiple>
                    </label><br>
                    <span *ngIf="isNotesError" class="error">Invalid Selection(only .txt allowed)</span>
                </div> -->
      <!-- </div> -->
      <div class="button-row">
        <button mat-raised-button type="submit" (click)="uploadFiles(); uploadFileForm['submitted'] = false" [disabled]="
            (audioFiles.length ||
              scriptFiles.length ||
              consultantNotes.length) &&
            (uploadFileForm.invalid || fileExtensionError)
          " [ngClass]="{
            btnenable:
              (audioFiles.length ||
                scriptFiles.length ||
                consultantNotes.length) &&
              uploadFileForm.valid &&
              !fileExtensionError
          }">
          Upload File
        </button>
      </div>
    </div>
  </form>
</mat-card>