<mat-card class="file-upload-wrap font-primary sm-w-100">
  <mat-card-header class="border-top-5 background-primary">
    <mat-card-title>Upload Files</mat-card-title>
  </mat-card-header>
  <form [formGroup]="uploadFileForm" #heroForm="ngForm">
    <div class="file-upload-container">
      <div class="grid grid-cols-2 sm-grid-cols-1 grill">
        <mat-form-field class="proportion mr-12">
          <mat-label>Select Region</mat-label>
          <mat-select formControlName="region" required ngDefaultControl (selectionChange)="getCountries($event)">
            <mat-option *ngFor="let region of regions" [value]="region" class="mat-option-custom">
              {{ region.regionName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="uploadFileForm.controls.region.errors?.required">
            Please select region
          </mat-error>
        </mat-form-field>
        <mat-form-field class="proportion">
          <mat-label>Select Country</mat-label>
          <mat-select formControlName="country" required ngDefaultControl (selectionChange)="getLanguages($event)">
            <mat-option *ngFor="let country of countries" [value]="country" class="mat-option-custom">
              {{ country.countryName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="uploadFileForm.controls.country.errors?.required">
            Please select country
          </mat-error>
        </mat-form-field>
      </div>
      <div class="grid grid-cols-2 sm-grid-cols-1 grill">
        <mat-form-field class="proportion mr-12">
          <mat-label>Select Source Language</mat-label>
          <mat-select formControlName="language" required ngDefaultControl>
            <mat-option *ngFor="let language of languages" [value]="language.languageName" class="mat-option-custom">
              {{ language.languageName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="uploadFileForm.controls.language.errors?.required">
            Please select language
          </mat-error>
        </mat-form-field>
        <mat-form-field class="proportion">
          <mat-label>Select Testament Type</mat-label>
          <mat-select formControlName="testamentType" required ngDefaultControl (selectionChange)="getBooks($event)">
            <mat-option *ngFor="let testament of testaments" [value]="testament" class="mat-option-custom">
              {{ testament }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="uploadFileForm.controls.testamentType.errors?.required">
            Please select Testament Type
          </mat-error>
        </mat-form-field>
      </div>
      <div class="grid grid-cols-2 sm-grid-cols-1 grill">
        <mat-form-field class="proportion mr-12">
          <mat-label>Select Book</mat-label>
          <mat-select formControlName="bookName" required ngDefaultControl (selectionChange)="getChapters($event)">
            <mat-option *ngFor="let book of books" [value]="book" class="mat-option-custom">
              {{ book }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="uploadFileForm.controls.bookName.errors?.required">
            Please select Book
          </mat-error>
        </mat-form-field>
        <mat-form-field class="proportion">
          <mat-label>Select Chapter</mat-label>
          <mat-select formControlName="chapter" required ngDefaultControl (selectionChange)="getYoutubeLink($event)">
            <mat-option *ngFor="let chapter of chapters" [value]="chapter" class="mat-option-custom">
              {{ chapter }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="uploadFileForm.controls.chapter.errors?.required">
            Please select Chapter
          </mat-error>
        </mat-form-field>
      </div>

      <div class="grid grill mx-2" style="width: 100%;">
      
          <mat-form-field class="mx-2">
            <mat-label>Paste Your Youtube Link</mat-label>
            <input matInput placeholder="" required ngDefaultControl formControlName="youtube"  [value]= "youtubeLink != undefined ? youtubeLink: '' ">
          </mat-form-field>

          


      </div>

      <div class="grid grid-cols-3 sm-grid-cols-1 grill text-center items-center">

        <div class="example-container" class="proportion">
          <span class="text-gray">Video Files </span>
          <br />
          <label class="btn-primary py-0 px-2 rounded-sm mat-raised-button">Upload Source Video
            <br />
            <input #videoFileInput hidden type="file" (change)="selectVideoFiles($event)" accept=".mp4" multiple />
          </label>
          <br />
          <span *ngIf="isVideoError" class="error">Invalid Selection(only .mp4 allowed)</span>
          <span *ngIf="!isVideoError">
            <span *ngFor="let video of videoFiles" class="list-no-decoration">
              {{ video.name }}
            </span>
          </span>
        </div>

        <div class="example-container" class="proportion">
          <span class="text-gray">Audio Files </span>
          <br />
          <label class="btn-primary py-0 px-2 rounded-sm mat-raised-button">Upload Source Audio
            <br />
            <input hidden #audioFileInput type="file" accept=".wav,.mp3" (change)="selectAudioFiles($event)" multiple />
          </label>
          <br />
          <span *ngIf="isAudioError" class="error">Invalid Selection(only .mp3,.wav allowed)</span>
          <span *ngIf="!isAudioError">
            <span *ngFor="let audio of audioFiles" class="list-no-decoration">
              {{ audio.name }}
            </span>
          </span>
        </div>


        

        <div class="example-container" class="proportion">
          <span class="text-gray">Source Script</span>
          <br />
          <label class="btn-primary py-0 px-2 rounded-sm mat-raised-button">Upload Source Script
            <br />
            <input hidden #textFileInput type="file" (change)="selectScriptFiles($event)"
              accept=".txt , .pdf  , .doc , .docx" multiple /> </label><br />
          <span *ngIf="isScriptError" class="error">Invalid Selection(only .txt , .pdf ,.doc , .docx allowed)</span>
          <span *ngIf="!isScriptError">
            <span *ngFor="let fileName of scriptFiles" class="list-no-decoration">
              {{ fileName.name }}
            </span>
          </span>
        </div>
        <!-- <div class="example-container" class="proportion">
                      <span class="text-gray">Consultant Notes </span>
                      <br />
                      <label class="btn-primary py-0 px-2 rounded-sm  mat-raised-button ">Upload Consultant Notes
                          <br />
                          <input hidden #consultantFileInput type="file" (change)="selectConsultantFiles($event)"
                              accept=".txt" multiple>
                      </label>
                      <span *ngIf="isNotesError" class="error">Invalid Selection(only .txt allowed)</span>
                      <span *ngIf="!isNotesError">
                          <span *ngFor="let notes of consultantNotes" class="list-no-decoration">
                              {{notes.name}}
                          </span>
  
                      </span>
                  </div> -->
      </div>
      <div class="button-row pt-4">
        <button mat-raised-button type="submit" (click)="uploadFiles();uploadFileForm['submitted']=false" [disabled]="
            (audioFiles.length ||
              scriptFiles.length ||
              consultantNotes.length) &&
            (uploadFileForm.invalid || fileExtensionError)
          " [ngClass]="{
            'btn-primary rounded-sm':
              (audioFiles.length ||
                scriptFiles.length ||
                consultantNotes.length) &&
              uploadFileForm.valid &&
              !fileExtensionError
          }">
          Upload File
        </button>
      </div>
    </div>
  </form>
</mat-card>